import React from 'react';

import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonIcon from '@mui/icons-material/Person';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import BallotIcon from '@mui/icons-material/Ballot';
import BlurLinearIcon from '@mui/icons-material/BlurLinear';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import DescriptionIcon from '@mui/icons-material/Description';
import AssistantIcon from '@mui/icons-material/Assistant';
import LabelIcon from '@mui/icons-material/Label';
import GetAppIcon from '@mui/icons-material/GetApp';
import { LocalOffer } from '@mui/icons-material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { GENDER } from 'src/components/questionnaire/Constants';
import LaunchIcon from '@mui/icons-material/Launch';

export const SIDEBAR_VALUE = {
  legend: 'legend',
  person: 'person',
  familyHistory: 'familyHistory',
  addRelative: 'addRelative',
  labels: 'labels',
  // epic integration
  emr: 'emr',
  tags: 'tags',
  riskFactors: 'riskFactors',
  phenotypes: 'phenotypes',
  genotypes: 'genotypes',
  diagnoses: 'diagnoses',
  clinicalNotes: 'clinicalNotes',
  suggestions: 'suggestions',
  export: 'export',
  setupPatientAccess: 'setupPatientAccess'
};

export const SIDEBAR_ITEMS = [
  {
    label: 'LEGEND',
    value: SIDEBAR_VALUE.legend,
    icon: <DashboardIcon fontSize="medium" />
  },
  {
    label: 'LABELS',
    value: SIDEBAR_VALUE.labels,
    icon: <LabelIcon fontSize="medium" />
  },
  {
    label: 'TAGS',
    value: SIDEBAR_VALUE.tags,
    icon: <LocalOffer fontSize="medium" />
  },
  {
    label: 'CLINICAL RECORDS',
    value: SIDEBAR_VALUE.clinicalNotes,
    icon: <DescriptionIcon fontSize="medium" />
  },
  {
    label: 'COPILOT',
    value: SIDEBAR_VALUE.suggestions,
    icon: <AssistantIcon fontSize="medium" />
  },
  {
    label: 'FAMILY HISTORY',
    value: SIDEBAR_VALUE.familyHistory,
    icon: <AccountTreeIcon fontSize="medium" />
  },
  {
    label: 'EXPORT',
    value: SIDEBAR_VALUE.export,
    icon: <GetAppIcon fontSize="medium" />
  }
];

export const getUserSidebarItems = ({ showPatientAccessLabel, showEmrTab }) =>
  [
    {
      label: 'LEGEND',
      value: SIDEBAR_VALUE.legend,
      icon: <DashboardIcon fontSize="medium" />
    },
    {
      label: 'LABELS',
      value: SIDEBAR_VALUE.labels,
      icon: <LabelIcon fontSize="medium" />
    },
    {
      label: 'ADD RELATIVE',
      value: SIDEBAR_VALUE.addRelative,
      icon: <PersonAddIcon fontSize="medium" />
    },
    // only show EMR if enabled
    ...(showEmrTab
      ? [
          {
            label: 'EMR',
            value: SIDEBAR_VALUE.emr,
            icon: <LaunchIcon fontSize="medium" />
          }
        ]
      : []),
    {
      label: 'PERSON',
      value: SIDEBAR_VALUE.person,
      icon: <PersonIcon fontSize="medium" />
    },
    // Show when user is proband & don't have email
    // showPatientAccessLabel && {
    //   label: 'SET UP PATIENT ACCESS',
    //   value: SIDEBAR_VALUE.setupPatientAccess,
    //   icon: <VerifiedUserIcon />
    // },
    {
      label: 'CLINICAL RECORDS',
      value: SIDEBAR_VALUE.clinicalNotes,
      icon: <DescriptionIcon fontSize="medium" />
    },
    {
      label: 'DISEASES',
      value: SIDEBAR_VALUE.diagnoses,
      icon: <LocalHospitalIcon fontSize="medium" />
    },
    {
      label: 'RISK FACTORS',
      value: SIDEBAR_VALUE.riskFactors,
      icon: <AssignmentLateIcon fontSize="medium" />
    },
    {
      label: 'PHENOTYPES',
      value: SIDEBAR_VALUE.phenotypes,
      icon: <BallotIcon fontSize="medium" />
    },
    {
      label: 'GENOTYPES',
      value: SIDEBAR_VALUE.genotypes,
      icon: <BlurLinearIcon fontSize="medium" />
    },
    {
      label: 'COPILOT',
      value: SIDEBAR_VALUE.suggestions,
      icon: <AssistantIcon fontSize="medium" />
    },
    {
      label: 'FAMILY HISTORY',
      value: SIDEBAR_VALUE.familyHistory,
      icon: <AccountTreeIcon fontSize="medium" />
    },
    {
      label: 'EXPORT',
      value: SIDEBAR_VALUE.export,
      icon: <GetAppIcon fontSize="medium" />
    }
  ].filter(Boolean);

export const STUDENT_SIDEBAR_ITEMS = [
  {
    label: 'LEGEND',
    value: SIDEBAR_VALUE.legend,
    icon: <DashboardIcon fontSize="medium" />
  },
  {
    label: 'LABELS',
    value: SIDEBAR_VALUE.labels,
    icon: <LabelIcon fontSize="medium" />
  },
  {
    label: 'EXPORT',
    value: SIDEBAR_VALUE.export,
    icon: <GetAppIcon fontSize="medium" />
  }
];

export const getStudentUserSidebarItems = (showPatientAccessLabel) =>
  [
    {
      label: 'LEGEND',
      value: SIDEBAR_VALUE.legend,
      icon: <DashboardIcon fontSize="medium" />
    },
    {
      label: 'ADD RELATIVE',
      value: SIDEBAR_VALUE.addRelative,
      icon: <PersonAddIcon fontSize="medium" />
    },
    {
      label: 'LABELS',
      value: SIDEBAR_VALUE.labels,
      icon: <LabelIcon fontSize="medium" />
    },
    {
      label: 'PERSON',
      value: SIDEBAR_VALUE.person,
      icon: <PersonIcon fontSize="medium" />
    },
    // Show when user is proband & don't have email
    showPatientAccessLabel && {
      label: 'SET UP PATIENT ACCESS',
      value: SIDEBAR_VALUE.setupPatientAccess,
      icon: <VerifiedUserIcon fontSize="medium" />
    },
    {
      label: 'EXPORT',
      value: SIDEBAR_VALUE.export,
      icon: <GetAppIcon fontSize="medium" />
    }
  ].filter(Boolean);

export const checkRelationValid = (selected_gender, deepCopy, formValues) => {
  const spouse = deepCopy.find((p) => p.pid === formValues.spouse);

  const child = deepCopy.some(
    (p) =>
      (p.mother === formValues.pid || p.father === formValues.pid) && !p.hidden
  );

  if (!spouse || !child) return { status: true };

  if (
    (selected_gender === GENDER.female &&
      [GENDER.female, GENDER.unknown].includes(spouse.gender)) ||
    (spouse.gender === GENDER.female &&
      [GENDER.female, GENDER.unknown].includes(selected_gender))
  ) {
    return {
      status: false,
      message:
        'This relationship has offspring and no valid sperm donor detected.  Please update gender. '
    };
  }

  const relationValid =
    (selected_gender === GENDER.female && spouse.gender === GENDER.male) ||
    (spouse.gender === GENDER.female && selected_gender === GENDER.male);

  if (relationValid) {
    return { status: true };
  } else {
    return {
      status: false,
      message:
        ' This relationship has offspring and no valid egg donor detected.  Please update gender. '
    };
  }
};

export const replacer = (key, value) => {
  if (typeof value === 'object' && value !== null) {
    if (key === 'parent_node') {
      return undefined;
    }
  }
  return value;
};
