import React, { useEffect, useState, useContext } from 'react';
import { t } from 'i18next';
import moment from 'moment';
import axios from 'src/axios';

import Grid from '@mui/material/Grid';
import Table from 'src/components/UI/table/Table';
import { APICONSTANTS } from 'src/components/Constants';
import { SnackContext } from 'src/store/ContextStore';

const ModificationHistoryModal = (props) => {
  const { modificationType, familyMemberId } = props;
  const [loading, setLoading] = useState(false);
  const { setSnack } = useContext(SnackContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!familyMemberId) return;

    const queryParams = modificationType
      ? `?recordType=${modificationType}`
      : '';

    axios
      .get(
        `${APICONSTANTS.getAuditLogsByFamilyMemberId.replace(
          ':familyMemberId',
          familyMemberId
        )}${queryParams}`
      )
      .then((res) => {
        setData(res.data);
      })
      .catch(() => {
        setSnack({
          status: true,
          msg: 'An error occurred while fetching details',
          severity: 'error'
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [familyMemberId]);

  const columns = [
    {
      title: '#',
      render: (rowData) => rowData.tableData.id + 1,
      width: 10,
      sorting: false
    },
    { title: t('E-mail'), field: 'email' },
    { title: t('Event'), field: 'event', sorting: false },
    { title: t('Description'), field: 'description', sorting: false },
    {
      title: t('Date'),
      field: 'createdAt',
      render: (rowData) => (
        <div>{moment(rowData.createdAt).format('MMMM Do YYYY, h:mm a')}</div>
      )
    },
    { title: t('IP Address'), field: 'ipAddress', sorting: false }
  ];

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Table
          rows={data}
          columns={columns}
          pageSize={10}
          title=""
          dense={true}
          pageSizeOptions={[5, 10, 20, 30, 40, 50]}
        />
      </Grid>
    </Grid>
  );
};

export default ModificationHistoryModal;
