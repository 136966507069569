import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'src/axios';
import PasswordInput from 'src/components/UI/input/PasswordInput';
import TextInput from '../UI/input/TextInput';
import { UserContext } from 'src/store/ContextStore';
import {
  APICONSTANTS,
  CONSTANTS,
  RECAPTCHA_SITE_KEY
} from 'src/components/Constants';
import logo from 'src/assets/images/trakgeneLogo.png';
import { injectGoogleCaptchaScript, validateEmail } from 'src/util/util';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    marginInline: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '40%',
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  logo: {
    width: '40%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
}));

const StudentRegister = () => {
  const classes = useStyles();
  const history = useHistory();

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState({ status: false });

  const isValid = () => !!email && !!password && !!confirmPassword;

  const recaptcha = React.useRef('');

  React.useEffect(() => {
    axios
      .get('/recaptcha')
      .then((res) => {
        injectGoogleCaptchaScript(null, res.data.siteKey);
        recaptcha.current = res.data.siteKey;
      })
      .catch((err) => console.error(err));
  }, []);

  //func to verify email and pass
  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (alert.status) setAlert((prev) => ({ ...prev, status: false }));

    if (!validateEmail(email)) {
      setAlert({ status: true, severity: 'error', msg: 'Invalid email!' });
      return;
    }
    if (password.length < 6) {
      setAlert({
        status: true,
        severity: 'error',
        msg: 'Please enter a secure password'
      });
      return;
    }
    if (password !== confirmPassword) {
      setAlert({
        status: true,
        severity: 'error',
        msg: "Password doesn't match"
      });
      return;
    }

    window.grecaptcha.ready(function () {
      try {
        window.grecaptcha.execute(recaptcha.current).then(function (grToken) {
          axios
            .post('/login/register/student', { email, password, grToken })
            .then((res) => {
              setLoading(false);
              if (res.data.exists) {
                setAlert({
                  status: true,
                  severity: 'error',
                  msg: 'Student already registered'
                });
                return;
              }
              if (res.data.status) history.push(CONSTANTS.signinPath);
            })
            .catch((err) => {
              setLoading(false);
              let error = err?.response?.data?.error ?? 'An error occurred';
              setAlert({
                status: true,
                severity: 'error',
                msg: error
              });
              setPassword('');
            });
        });
      } catch (err) {
        setAlert({
          status: true,
          severity: 'error',
          msg: 'Bot Detected'
        });
        setPassword('');
      }
    });
  };

  return (
    <div className={classes.paper}>
      <div className={classes.logo}>
        <img style={{ width: '100%' }} src={logo} alt="Trakgene Logo" />
      </div>
      <form className={`${classes.form} user-form`} onSubmit={onSubmit}>
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          {alert.status && (
            <Grid item xs={12}>
              <Alert variant="filled" severity={alert.severity}>
                {alert.msg}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextInput
              label="Email"
              name="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoFocus
              autoComplete="email"
            />
          </Grid>

          <Slide direction="right" in={true} mountOnEnter unmountOnExit>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <PasswordInput
                  ariaLabel="enter password"
                  label="Password"
                  name="newPassword"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12}>
                <PasswordInput
                  ariaLabel="enter password"
                  label="Confirm Password"
                  name="newPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  disabled={loading}
                />
              </Grid>

              <Grid item xs={4}>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={loading || !isValid()}
                  endIcon={loading ? <CircularProgress size={15} /> : undefined}
                  fullWidth
                  onSubmit={onSubmit}
                >
                  Register
                </Button>
              </Grid>

              <Grid item xs={12}>
                Already have an account?
                <Link to={CONSTANTS.signinPath}>
                  <strong>Sign in</strong>
                </Link>
              </Grid>
            </Grid>
          </Slide>
        </Grid>
      </form>
    </div>
  );
};

export default StudentRegister;
