import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import { useAutoTranslation } from 'src/hooks/useTranslate';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    gap: 15
  },
  boxHolder: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: '1rem',
    width: '100%'
  },
  name: {
    display: 'inline-block',
    maxWidth: '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  id: {
    marginLeft: 5
  }
});

export default function PhenotypesLegend({ phenotypes, id }) {
  const classes = useStyles();

  const t = useAutoTranslation();

  return (
    <>
      <Grid item>
        <Grid container className={classes.container} id={id}>
          {phenotypes.map((el, i) => {
            return (
              <div key={i} className={classes.boxHolder}>
                <span className={classes.name}>{t(el.name)}</span>
                <span className={classes.id}>({el.id})</span>
              </div>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
}
