import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Link, useHistory } from 'react-router-dom';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ThemeContext, UserContext } from 'src/store/ContextStore';
import { APICONSTANTS, CONSTANTS } from 'src/components/Constants';
import axios from 'src/axios';
import logo from 'src/assets/images/trakgeneLogo.png';

import HelpIcon from '@mui/icons-material/Help';
import { useTourContext } from 'src/components/tour/TourContext';

import TranslationMenu from 'src/components/settings/TranslationMenu';
import { useAutoTranslation } from 'src/hooks/useTranslate';
import SystemAnnouncements from 'src/components/admin/SystemAnnouncements';
import { AlignHorizontalCenter } from '@mui/icons-material';

// import CustomDrawer from './CustomDrawer';

const drawerWidth = 200;

export default React.memo(function CustomAppBar(props) {
  const {
    theme: { appearance, navbar, navbarText, logoURL, logoName, appbarHeight }
  } = React.useContext(ThemeContext);

  const offset = appbarHeight ? appbarHeight : 65;

  const { user, setUser } = React.useContext(UserContext);
  const { userType } = user || {};
  const { startTour } = useTourContext();

  const t = useAutoTranslation();

  const useStyles = makeStyles((theme) => ({
    offset: {
      minHeight: offset
      // [theme.breakpoints.down('sm')]: {
      //   minHeight: offset + 20,
      // },
    },
    root: {
      flexGrow: 1
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: `${navbar ?? appearance.color}!important`,
      transition: 'background-color 1s',
      color: navbarText ?? appearance.text,
      // padding: '20px 20px 20px 0px',
      borderBottom: '1px solid #c1c1c170',
      boxShadow: 'none',
      height: offset
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: `${theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })}, background-color 1s`
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        backgroundColor: 'red',
        display: 'none'
      }
    },
    hide: {
      display: 'none'
    },
    link: {
      textDecoration: 'none !important',
      fontWeight: 'bolder',
      color: navbarText ?? appearance.text,
      [theme.breakpoints.down('md')]: {
        fontSize: 16
      }
    },
    logo: {
      height: 40,
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    menu: {
      '& .MuiPaper-root': {
        backgroundColor: appearance.color,
        color: appearance.text,
        boxShadow: `3px 3px 15px ${appearance.shade}`
      }
    },
    systemmessage: {
      '& .MuiPaper-root': {
        top: '9px',
        left: '25%',
        right: '25%',
        position: 'absolute',
        margin: 'auto'
      }
    },
    PEP: {
      [theme.breakpoints.down('md')]: {
        position: 'absolute'
      }
    },
    smlogo: {
      display: 'none',
      [theme.breakpoints.down('md')]: {
        display: 'block',
        height: 20
      }
    }
  }));

  const classes = useStyles();
  const history = useHistory();
  // use to open and close drawer

  const email = user && user.email;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const clearSession = () => {
    setAnchorEl(null);
    axios.get(APICONSTANTS.logout).then(() => {
      history.push(CONSTANTS.signinPath);
      setUser(null);
      localStorage.setItem('2FAloggedin', 'false');
    });
  };

  const handleStartTour = () => {
    startTour(userType);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      // keepMounted
      className={classes.menu}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {email && (
        <Typography
          variant="caption"
          display="block"
          style={{ paddingLeft: 15, paddingRight: 15 }}
        >
          {t(`Logged In as`)}:
        </Typography>
      )}
      {email && (
        <Typography
          variant="caption"
          display="block"
          style={{ paddingLeft: 15, paddingRight: 15 }}
        >
          {email}
        </Typography>
      )}
      {props.menuItems &&
        React.cloneElement(props.menuItems, { handleMenuClose })}
      <MenuItem onClick={clearSession}>{t(`Log Out`)}</MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <div className={classes.systemmessage}>
          <SystemAnnouncements />
        </div>
        <Toolbar>
          <img
            alt="Logo"
            src={logoURL && logoName ? logoURL : logo}
            className={classes.logo}
          />
          <div className="d-inline-block ml-auto">
            {/* only show image if present */}
          </div>
          <Typography variant="h5" className={classes.PEP}>
            <Link to={CONSTANTS.adminPath} className={classes.link}>
              <img
                alt="TrakGene"
                src={logoURL && logoName ? logoURL : logo}
                className={classes.smlogo}
              />
              {props.title ? t(props.title) : t('')}
            </Link>
          </Typography>
          <div className={classes.root} />

          <TranslationMenu color={navbarText} />

          <IconButton
            edge="end"
            onClick={handleStartTour}
            color="inherit"
            fontSize="large"
            size="large"
          >
            <HelpIcon />
          </IconButton>

          {user && (
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              style={{ marginRight: '10px' }}
              fontSize="small"
              size="large"
            >
              <AccountCircle />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      {renderMenu}
      <div className={classes.offset} />

      {/* {props.navItems?.length > 0 && (
      <CustomDrawer open={open} setOpen={setOpen} navItems={props.navItems} />
    )} */}
    </>
  );
});
