import React from 'react';
import { useAutoTranslation } from 'src/hooks/useTranslate';
import { APICONSTANTS } from '../Constants';
import axios from 'src/axios';
import SnackBar from 'src/components/UI/snack/SnackBar';
import MuiAlert from '@mui/material/Alert';

export default function SystemAnnouncements() {
  const t = useAutoTranslation();

  const [systemAnnouncement, setSystemAnnouncement] = React.useState('');
  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    const apiRequest = async () => {
      const systemAnnouncements = await axios.get(
        APICONSTANTS.getSystemAnnouncement
      );
      if (!systemAnnouncements?.data?.result?.systemAnnouncement) return;
      setOpen(true);
      setSystemAnnouncement(
        t(systemAnnouncements?.data.result.systemAnnouncement)
      );
    };
    apiRequest();
  }, []);

  return (
    <>
      {systemAnnouncement && open && (
        <MuiAlert severity="info">{systemAnnouncement}</MuiAlert>
      )}
    </>
  );
}
