import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

export default function CustomIconBtn(props) {
  const { type, onClick, size } = props;

  const style = props.secondary ? undefined : { color: props.color ?? '#000' };
  const options = {
    fontSize: size ?? 'small',
    style,
    color: props.secondary ? 'secondary' : undefined
  };

  let icon = '',
    tooltip = '',
    disabled = props.disabled ?? undefined;
  switch (type) {
    case 'add': {
      tooltip = 'Add';
      icon = <AddCircleIcon {...options} />;
      break;
    }
    case 'edit': {
      tooltip = 'Edit';
      icon = <EditIcon {...options} />;
      break;
    }
    case 'delete': {
      tooltip = 'Delete';
      icon = <DeleteIcon {...options} />;
      break;
    }
    case 'view': {
      tooltip = 'View';
      icon = <VisibilityIcon {...options} />;
      break;
    }
    case 'view-pedigree': {
      tooltip = 'View';
      icon = <AccountTreeIcon {...options} />;
      break;
    }
    case 'imported': {
      tooltip = 'Mark Family As Imported';
      icon = <ImportExportIcon {...options} />;
      break;
    }
    case 'loading': {
      disabled = true;
      icon = <CircularProgress size={15} />;
      break;
    }
    default: {
      tooltip = props.tooltip;
      icon = props.icon;
    }
  }
  return (
    <Tooltip title={props.tooltip ?? tooltip} placement="top" arrow>
      <IconButton onClick={onClick} disabled={disabled} size="large">
        {icon}
      </IconButton>
    </Tooltip>
  );
}
