import React from 'react';

import {
  FormLabel,
  Grid,
  Button,
  Stack,
  Chip,
  Link,
  Typography,
  Box,
  TextField,
  Modal
} from '@mui/material';

import CustomDialog from '../dialog/CustomDialog';
import ModalButtons from '../button/ModalButtons';

import axios from 'axios';

import { APICONSTANTS } from 'src/components/Constants';

export default function UploadFileList({ value, onChange }) {
  const [fileLinks, setFileLinks] = React.useState([]);
  const [modalOpen, setModalOpen] = React.useState(false);

  const defaultValues = { filePath: '', linkTitle: '' };
  const [formValues, setFormValues] = React.useState(defaultValues || {});

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value
    });
  };

  const handleBrowse = (e) => {
    console.log(e.target.files);
    setFormValues({
      ...formValues,
      filePath: e.target?.files[0]?.name
    });
  };

  const handleUpload = async (e) => {
    if (e.target.files.length !== 0) {
      const formData = new FormData();
      formData.append('file_attachment', e.target.files[0]);
      const res = await axios.post(
        `/api/${APICONSTANTS.fileAttachment}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      let links = fileLinks;
      links.push(res.data.fileURL);
      setFileLinks(links);
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      let links = [];
      let i = 0;
      for (let x in value) {
        const response = await axios.get(
          `/api/${APICONSTANTS.fileAttachment}`,
          {
            params: {
              fileName: value[x]
            }
          }
        );
        links[i] = response.data.fileURL;
        i++;
      }
      setFileLinks(links);
    };
    fetchData();
  }, []);

  const handleFile = (e) => {
    handleUpload(e);
    let fileAttachments = value;
    fileAttachments.push(e.target?.files[0]?.name);
    onChange(fileAttachments);
  };

  const [inputKey, setInputKey] = React.useState(Date.now());
  const handleDelete = (e, deletedIndex) => {
    setInputKey(Date.now());
    let fileAttachments = value;
    const deleted = value[deletedIndex];

    axios.delete(`/api/${APICONSTANTS.fileAttachment}`, {
      params: {
        fileName: deleted
      }
    });

    fileAttachments = fileAttachments.filter((e, i) => i !== deletedIndex);
    onChange(fileAttachments);
  };

  const handleClick = (e, fileLink) => {
    e.stopPropagation();
    e.preventDefault();
    window.open(fileLink, '_blank');
  };

  return (
    <>
      <Grid item xs={3} style={{ paddingTop: '1.5rem' }}>
        <FormLabel sx={{ fontWeight: 'bold', color: 'black' }}>
          File Attachments
        </FormLabel>
      </Grid>
      <Grid item xs={4}></Grid>
      <Grid item xs={5}>
        <Button
          sx={{ float: 'right' }}
          variant="contained"
          color="secondary"
          component="label"
        >
          Upload Attachments
          <input
            key={inputKey}
            accept="image/*,.pdf,.txt"
            hidden
            type="file"
            onChange={handleFile}
          />
        </Button>

        <Stack
          sx={{ float: 'right' }}
          spacing={1}
          style={{ paddingTop: '1rem' }}
        >
          {value.map((attachment, i) => (
            <Chip
              key={i}
              label={attachment}
              variant="outlined"
              onClick={(event) => handleClick(event, fileLinks[i])}
              onDelete={(e) => handleDelete(e, i)}
            />
          ))}
        </Stack>
      </Grid>
      <Modal
        open={modalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomDialog
          open={modalOpen}
          handleClose={(e) => setModalOpen(false)}
          maxWidth={'sm'}
          minHeight={'auto'}
          title={`Add Link To File`}
        >
          <form className="user-form">
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Grid sx={{ paddingBlock: 1 }}>
                  <FormLabel sx={{ fontWeight: 'bold', color: 'black' }}>
                    File Path
                  </FormLabel>
                </Grid>
                <Box sx={{ display: 'flex', position: 'relative' }}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    name="filePath"
                    value={formValues.filePath}
                  />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Grid sx={{ paddingBlock: 1 }}>
                  <FormLabel sx={{ fontWeight: 'bold', color: 'white' }}>
                    .
                  </FormLabel>
                </Grid>
                <Box
                  sx={{
                    display: 'flex',
                    position: 'relative'
                  }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    component="label"
                    sx={{ padding: '1rem', width: '100%' }}
                    onClick={() => setModalOpen(true)}
                  >
                    Browse
                    <input
                      key={inputKey}
                      accept="image/*,.pdf,.txt"
                      hidden
                      type="file"
                      onChange={handleBrowse}
                    />
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid sx={{ paddingBlock: 1 }}>
                  <FormLabel sx={{ fontWeight: 'bold', color: 'black' }}>
                    Link Title
                  </FormLabel>
                </Grid>
                <Box sx={{ display: 'flex', position: 'relative' }}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    name="linkTitle"
                    value={formValues.linkTitle}
                  />
                </Box>
              </Grid>
              <ModalButtons
                hideContinueAdding={true}
                intermediateBtnLabel="Delete"
                addLabel={`Add Link To File`}
                handleClose={(e) => setModalOpen(false)}
              />
            </Grid>
          </form>
        </CustomDialog>
      </Modal>
    </>
  );
}
