import React from 'react';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useAutoTranslation } from 'src/hooks/useTranslate';
import { PEDIGREE } from 'src/components/Constants';

export default function LabelsMenu(props) {
  const { labelsSet, setLabelsSet } = props;

  const handleChange = (e) =>
    setLabelsSet({ ...labelsSet, [e.target.name]: e.target.checked });

  const t = useAutoTranslation();

  const labels = {
    firstName: 'First Name',
    lastName: 'Last Name',
    age: 'Age',
    upn: 'UPN',
    personNote: 'Person Note',
    phenotypesName: 'Phenotypes Name',
    phenotypesID: 'Phenotypes ID'
  };

  return (
    <Grid container alignItems="center">
      <Grid item>
        <FormGroup column>
          {Object.keys(PEDIGREE.defaultLabelFilters).map((label) => {
            return (
              <FormControlLabel
                key={label}
                control={
                  <Checkbox
                    checked={labelsSet[label]}
                    onChange={handleChange}
                    size="small"
                    name={label}
                  />
                }
                label={t(labels[label])}
              />
            );
          })}
        </FormGroup>
      </Grid>
    </Grid>
  );
}
