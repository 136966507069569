// Styles
import styles from './Tool.module.css';
// Libraries
import React from 'react';
import { useDrag } from 'react-dnd';
import { TEMPLATE_TYPES } from '../../constants/templateTypes';
import { FORM_TYPES } from '../../constants/formTypes';
import {
  CloudUpload,
  ShortText,
  CheckBox,
  ArrowDropDownCircleRounded,
  RadioButtonChecked,
  LinearScaleRounded,
  TextFields,
  Email,
  DateRange,
  Notes,
  Description,
  Home,
  Edit,
  UploadFile
} from '@mui/icons-material';
import { useAutoTranslation } from 'src/hooks/useTranslate';

function Tool({ toolName, tool, type }) {
  const [, drag] = useDrag(() => ({
    type: 'tool',
    item: { toolName: `${toolName}` },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }));

  const toolItems = () => {
    switch (tool.key) {
      case FORM_TYPES.SHORT_TEXT:
        return <ShortText style={{ color: '#2ba9bf' }} />;
      case FORM_TYPES.LONG_TEXT:
        return <Notes style={{ color: '#2ba9bf' }} />;
      case FORM_TYPES.CHECKBOX_INPUT:
        return <CheckBox style={{ color: '#fcba03' }} />;
      case FORM_TYPES.DROPDOWN:
        return <ArrowDropDownCircleRounded style={{ color: '#fcba03' }} />;
      case FORM_TYPES.MULTIPLE_CHOICE:
        return <RadioButtonChecked style={{ color: '#fcba03' }} />;
      case FORM_TYPES.LINEAR_SCALE:
        return <LinearScaleRounded style={{ color: '#fcba03' }} />;
      case FORM_TYPES.EMBED_CONTENT:
        return <TextFields style={{ color: '#2E3747' }} />;
      case FORM_TYPES.EMAIL_INPUT:
        return <Email style={{ color: '#2ba9bf' }} />;
      case FORM_TYPES.DATE_TIME_INPUT:
        return <DateRange style={{ color: '#2ba9bf' }} />;
      case FORM_TYPES.FILE_UPLOAD:
        return <UploadFile style={{ color: '#2E3747' }} />;
      case FORM_TYPES.SIGNATURE:
        return <Edit style={{ color: '#2E3747' }} />;
      case TEMPLATE_TYPES.DEMOGRAPHICS:
        return <Description style={{ color: '#fc036b' }} />;
      case TEMPLATE_TYPES.ADDRESS:
        return <Home style={{ color: '#fc036b' }} />;
      default:
        return <></>;
    }
  };

  const t = useAutoTranslation();

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        paddingInline: '10px',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        // margin: "10px",
        alignItems: 'center'
      }}
      ref={drag}
    >
      {toolItems()}
      <div className={styles.ToolContainer}>{t(toolName)}</div>
    </div>
  );
}

export default Tool;
