import React from 'react';

import { Modal, Grid, TextField } from '@mui/material';
import ModalButtons from 'src/components/UI/button/ModalButtons';
import CustomDialog from 'src/components/UI/dialog/CustomDialog';
import { set_Cache } from './PedigreeChart/cache';
import { CONSTANTS, APICONSTANTS } from '../Constants';
import { CONSTANTS as c } from 'src/components/questionnaire/Constants';
import { useAutoTranslation } from 'src/hooks/useTranslate';
import axios from 'axios';
import { AppContext, ThemeContext, SnackContext } from 'src/store/ContextStore';
import { EVENTS } from 'src/components/admin/appReducer';

import * as d3 from 'd3';
import { replacer } from './pedigree-sidebar/util';

export default function DiagnosisModal({
  deepCopy,
  setDeepCopy,
  setDataset,
  setPedigreeState,
  diagnoses
}) {
  const { setSnack } = React.useContext(SnackContext);

  const selectRadius = 250;
  const { theme } = React.useContext(ThemeContext);

  const { content, dispatchUpdate } = React.useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user } = users;
  const [modalOpen, setModalOpen] = React.useState(false);
  const [currentDiagnosis, setCurrentDiagnosis] = React.useState('none');
  const [diagnosisAge, setDiagnosisAge] = React.useState('');
  let draggableData = [];

  let hold = React.useRef(deepCopy);
  let chosen = React.useRef(-1);

  const deselectAllOutlines = () => {
    d3.selectAll('.te')
      .style('stroke-width', function (d, i) {
        return '.11em';
      })
      .style('stroke', 'black');
  };

  const selectOutline = (index) => {
    d3.selectAll('.te')
      .style('stroke-width', function (d, i) {
        return i === index ? '.25em' : '.11em';
      })
      .style('stroke', function (d, i) {
        return i === index ? theme.accent : 'black';
      });
  };

  const updateData = (dataset) => {
    setPedigreeState(CONSTANTS.pedigreeState.modified);
    setDeepCopy(() => {
      return JSON.parse(JSON.stringify(dataset, replacer));
    });
    setDataset(dataset);
  };

  let user_id = React.useMemo(() => {
    return user?.user_id || user?._id;
  }, [user]);

  //let proBandId = useMemo(() => {
  //  return data?.data?.proBandId;
  //}, [data]);

  const getNodeFromIndex = (index) => {
    let res = {};
    d3.selectAll('.te').datum((d, i) => {
      if (i == index) {
        res = d?.data;
        if (!res) res = d;
      }
    });
    return res;
  };

  const [loading, setLoading] = React.useState(false);
  const [fetchedDiagnoses, setFetchedDiagnoses] = React.useState([]);

  const handleSubmit = async (index, diagnosis, age, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const node = getNodeFromIndex(index);
    const id = node.id || node.currentId;
    const proBandId = node.proBandId;
    const familyMemberId = proBandId ? proBandId : node.familyMemberId;

    if (!id || !user_id)
      return setSnack({
        status: true,
        msg: 'Kindly save pedigree first!',
        severity: 'error'
      });

    diagnosis.age = age;
    const selectedItems = [diagnosis];

    if (selectedItems?.length === 0) {
      return setSnack({
        status: true,
        msg: 'No diseases selected. Please add at least one disease to proceed.',
        severity: 'error'
      });
    }

    setLoading(true);
    const payload = {
      familyId: user.familyId,
      familyMemberId,
      user_id,
      isProband: proBandId ? true : false,
      diagnoses: selectedItems
    };

    axios
      .post(`/api/${APICONSTANTS.diagnoses}`, payload)
      .then((res) => {
        if (res.data?.success) {
          if (res.data?.allDiagnoses) {
            setFetchedDiagnoses(res.data.allDiagnoses);
            dispatchUpdate({
              type: EVENTS.SET_PEDIGREE_DIAGNOSES,
              value: res.data.diagnoses
            });

            const new_dataset = deepCopy.map((elem) => {
              if (elem.pid === node.pid) {
                return {
                  ...elem,
                  diagnoses: res.data.allDiagnoses
                };
              }
              return elem;
            });
            updateData(new_dataset);
          }
          setSnack({
            status: true,
            msg: 'Diagnoses saved successfully',
            severity: 'success'
          });
        }
      })
      .catch((err) =>
        setSnack({
          status: true,
          msg: err.response.data.error ?? 'An error has occurred!',
          severity: 'error'
        })
      )
      .finally(() => setLoading(false));

    setModalOpen(false);
    setDiagnosisAge('');
    deselectAllOutlines();
  };

  const distance = (point1, point2) => {
    return Math.sqrt(
      Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2)
    );
  };

  function dragstarted(d) {
    d3.select(this).style('opacity', 1);
    setCurrentDiagnosis(d.subject.diagnosis);
  }

  function dragged(d) {
    var xy = d3.pointer(d.sourceEvent);

    let mousePos = { x: xy[0], y: xy[1] };

    let choice = { index: 0, dist: 1000 };
    const members = document.getElementsByClassName('node');

    for (let i = 0; i < members.length; i++) {
      let ctm = members[i].getScreenCTM();
      let nodePos = { x: ctm.e, y: ctm.f };
      let dist = distance(mousePos, nodePos);
      if (dist < choice.dist) choice = { index: i, dist };
    }

    deselectAllOutlines();
    if (choice.dist < selectRadius) {
      selectOutline(choice.index);
    }

    d3.select(this)
      .datum(function (a) {
        return {
          id: d.subject.id,
          diagnosis: d.subject.diagnosis,
          x: d.x - 10,
          y: d.y + 5
        };
      })
      .attr('transform', (d) => `translate(${d.x},${d.y})`);
  }

  function dragended(d) {
    var dragged = d3.select(this);

    var xy = d3.pointer(d.sourceEvent);

    let mousePos = { x: xy[0], y: xy[1] };

    let choice = { index: 0, dist: 1000 };
    const members = document.getElementsByClassName('node');

    for (let i = 0; i < members.length; i++) {
      let ctm = members[i].getScreenCTM();
      let nodePos = { x: ctm.e, y: ctm.f };
      let dist = distance(mousePos, nodePos);
      if (dist < choice.dist) choice = { index: i, dist };
    }

    dragged
      .datum(function (a) {
        return {
          id: d.subject.id,
          diagnosis: d.subject.diagnosis,
          x: 0,
          y: draggableData[d.subject.id].y
        };
      })
      .attr('transform', (d) => `translate(${d.x},${d.y})`)
      .style('opacity', 0);
    if (choice.dist < selectRadius) {
      if (
        d.subject.diagnosis !== 'other' &&
        !c.pregnanciesDiagnoses.includes(d.subject.diagnosis)
      ) {
        setCurrentDiagnosis(d.subject.diagnosis);
        chosen.current = choice.index;
        setModalOpen(true);
      } else {
        deselectAllOutlines();
        handleSubmit(choice.index, d.subject.diagnosis, diagnosisAge);
      }
    }
  }
  const t = useAutoTranslation();

  const getTextColour = (c) => {
    c = c.substring(1); // strip #
    var rgb = parseInt(c, 16); // convert rrggbb to decimal
    var r = (rgb >> 16) & 0xff; // extract red
    var g = (rgb >> 8) & 0xff; // extract green
    var b = (rgb >> 0) & 0xff; // extract blue

    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luma < 200 ? 'white' : 'black';
  };

  const lineWidth = 30;
  React.useEffect(() => {
    var diagnosisModal = d3.select('.diagnosisModal');
    let svg = diagnosisModal.select('svg');
    svg.selectAll('.dragon').remove();

    let tally = 0;
    diagnoses.map((el, i) => {
      const height = Math.ceil(diagnoses[i].name.length / lineWidth);
      draggableData.push({
        id: i,
        x: -10,
        y: 15 * i + 15 * tally + (height - 1) * 5,
        diagnosis: diagnoses[i],
        width: 300
      });
      tally += Math.ceil(diagnoses[i].name.length / 28);
    });

    let text = svg
      .selectAll('.dragon')
      .data(draggableData)
      .enter()
      .append('g')
      .attr('transform', (d) => `translate(${d.x},${d.y})`)
      .attr('opacity', 0)
      .call(
        d3
          .drag()
          .on('start', dragstarted)
          .on('drag', dragged)
          .on('end', dragended)
      );

    text
      .append('rect')
      .attr('z-index', 999999)
      .attr('height', 30)
      .attr('width', 300)
      .attr('rx', 15)
      .style('border', 'grey 1px solid')
      .attr('fill', (d) => d.diagnosis.color)
      .raise();

    text
      .append('text')
      .text((d) => t(d.diagnosis.name))
      .attr('y', 20)
      .attr('x', 25)
      .attr('font-family', `"Roboto", "Helvetica", "Arial", sans-serif`)
      .style('font-size', '1rem')
      .style('fill', (d) => getTextColour(d.diagnosis.color));

    text.each(function (e, i) {
      var bbox = d3.select(this).select('text').node().getBBox();
      var width = bbox.width;
      d3.select(this)
        .select('rect')
        .attr('width', width + 50);
    });
  }, [diagnoses]);

  React.useEffect(() => {
    if (deepCopy) hold.current = deepCopy;
  });

  const handleCloseModal = () => {
    d3.selectAll('.te')
      .style('stroke-width', function (d, i) {
        return '.11em';
      })
      .style('stroke', 'black');
    setModalOpen(false);
  };
  return (
    <div className="diagnosisModal" style={{ position: 'absolute' }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 300 300"
        overflow="visible"
      />
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CustomDialog
          maxWidth={'sm'}
          title={`Age ${currentDiagnosis.name} Diagnosed`}
          open={modalOpen}
          handleClose={handleCloseModal}
          minHeight={'auto'}
          onClose={true}
        >
          <form className="user-form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  onChange={(e) => setDiagnosisAge(e.target.value)}
                  name={currentDiagnosis}
                  value={diagnosisAge}
                />
              </Grid>
              <ModalButtons
                hideContinueAdding={true}
                handleClose={handleCloseModal}
                onClick={async (e) =>
                  handleSubmit(
                    chosen.current,
                    currentDiagnosis,
                    diagnosisAge,
                    e
                  )
                }
                addLabel={`Save`}
              />
            </Grid>
          </form>
        </CustomDialog>
      </Modal>
    </div>
  );
}
