import * as d3 from 'd3';
let zm;

function zooming(e, opts, pedref) {
  //   opts.DEBUG && console.log("zoom", d3.event, e.transform);
  let t = e.transform;
  let k = t.k && t.k !== 1 ? t.k : undefined;
  //   setposition(opts, t.x, t.y, k);
  let ped = d3.select(pedref.current).select('.diagram');
  ped.attr(
    'transform',
    'translate(' + t.x + ',' + t.y + ')' + (k ? ' scale(' + k + ')' : '')
  );
}

export function init_zoom(opts, svg, pedref) {
  // offsets
  let xi = opts.symbol_size / 2;
  let yi = -opts.symbol_size * 2.5;

  zm = d3
    .zoom()
    .scaleExtent([opts.zoomIn, opts.zoomOut])
    .on('zoom', function (e) {
      zooming(e, opts, pedref);
      localStorage.setItem('zoom', JSON.stringify(e.transform));
    });

  svg.call(zm);

  const zoom = JSON.parse(localStorage.getItem('zoom'));

  let transform;
  if (zoom) {
    const { x, y, k } = zoom;
    transform = d3.zoomIdentity.translate(x, y).scale(k);
  } else {
    let x = xi + pedref.current.offsetWidth / 4;
    let y = yi;

    transform = d3.zoomIdentity.scale(1).translate(x, y);
  }

  svg.call(zm.transform, transform);
}

export function btn_zoom(svg, scale) {
  svg.transition().duration(50).call(zm.scaleBy, scale);
}
