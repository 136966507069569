import React, { useEffect, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  adjust_coords,
  buildTree,
  consanguity,
  flatten,
  getDepth,
  getNodeByName,
  getTwins,
  get_partners,
  get_svg_dimensions,
  get_tree_dimensions,
  linkNodes,
  roots,
  getPathCoordinates,
  getRenderPath,
  genderIconMap,
  get_bracket,
  checkFertile,
  getConnectedTwins,
  getQuestionPath,
  getAcronym,
  getLabelPos,
  wrap
} from './utils';
import {
  FERTILITY,
  GENDER,
  spouseRelations
} from 'src/components/questionnaire/Constants';
import { TWIN_IDENTIFIERS } from '../pedigreeUtil';
import * as d3 from 'd3';
import { btn_zoom, init_zoom } from './zoom';
import { Redo, Undo, ZoomIn, ZoomOut } from '@mui/icons-material';
import { Button } from '@mui/material';
import { getPos, getCount } from './cache';

const useStyles = makeStyles({
  btnContainer: {
    gap: 10,
    display: 'flex',
    position: 'absolute',
    top: 12,
    left: 12
  }
});

function group_top_level(dataset) {
  if (dataset) {
    for (let i = 0; i < dataset.length; i++) {
      if (getDepth(dataset, dataset[i].pid) === 2) dataset[i].top_level = true;
    }

    let top_level = [];
    let top_level_seen = [];
    for (let i = 0; i < dataset.length; i++) {
      let node = dataset[i];
      if ('top_level' in node && top_level_seen.indexOf(node.pid) === -1) {
        top_level_seen.push(node.pid);
        top_level.push(node);
        let ptrs = get_partners(dataset, node);
        for (let j = 0; j < ptrs.length; j++) {
          if (top_level_seen.indexOf(ptrs[j]) === -1) {
            top_level_seen.push(ptrs[j]);
            top_level.push(getNodeByName(dataset, ptrs[j]));
          }
        }
      }
    }

    let newdataset = dataset.filter(function (val) {
      return !('top_level' in val && val.top_level);
    });

    for (let i = top_level.length; i > 0; --i)
      newdataset.unshift(top_level[i - 1]);

    return newdataset;
  }
}

function Render({
  options,
  dataset,
  handleClick,
  colors,
  // undo,
  // redo,
  onNodeClick,
  onCloseMenu
}) {
  const pedRef = useRef(null);
  const classes = useStyles();
  //   const [dataset, setdataset] = useState(_dataset);

  dataset = group_top_level(dataset);
  //   setdataset([]);

  function check_ptr_link_clashes(opts, anode) {
    let root = roots[opts.targetDiv];
    let flattenNodes = flatten(root);
    let mother, father;
    if (anode.pid) {
      anode = getNodeByName(flattenNodes, anode.pid);
      if (!('mother' in anode.data)) return null;
      mother = getNodeByName(flattenNodes, anode.data.mother);
      father = getNodeByName(flattenNodes, anode.data.father);
    } else {
      mother = anode.mother;
      father = anode.father;
    }

    let x1 = mother.x < father.x ? mother.x : father.x;
    let x2 = mother.x < father.x ? father.x : mother.x;
    let dy = mother.y;

    // identify clashes with other nodes at the same depth
    let clash = flattenNodes.filter(function (bnode) {
      return !bnode.data.hidden &&
        bnode.data.pid !== mother.data.pid &&
        bnode.data.pid !== father.data.pid &&
        bnode.y === dy &&
        bnode.x > x1 &&
        bnode.x < x2
        ? bnode.x
        : null;
    });
    return clash.length > 0 ? clash : null;
  }

  const has_gender = (data, d3) => {
    return (
      genderIconMap(data, d3) === 'male' || genderIconMap(data, d3) === 'female'
    );
  };

  const getName = (data) => {
    const firstName = data?.firstName || '';
    const lastName = data?.lastName || '';

    const showFirstName = options.labelsSet.firstName;
    const showLastName = options.labelsSet.lastName;

    if (showFirstName && showLastName) return firstName + ' ' + lastName;
    else if (showFirstName) return firstName;
    else if (showLastName) return lastName;
    else return '';
  };

  let svg;

  useEffect(() => {
    const svg_dimensions = get_svg_dimensions(options);

    const svgContainer = d3.select(pedRef.current);
    svgContainer.selectAll('svg').remove();

    svg = svgContainer
      .append('svg:svg')
      .attr('id', 'pedigree-chart')
      .attr('width', svg_dimensions.width);
    // .attr('height', svg_dimensions.height);

    svg
      .append('rect')
      .attr('class', 'canvas')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('rx', 6)
      .attr('ry', 6)
      // .style('stroke', 'black')
      .style('fill', colors.shade)
      // .style('stroke-width', 8);
      .on('contextmenu', function (e) {
        e.preventDefault();
        e.stopPropagation();
        onCloseMenu();
      })
      .on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        onCloseMenu();
      });

    const ped = svg.append('g').attr('class', 'diagram');

    const top_level = dataset.filter(function (val) {
      return 'top_level' in val && val.top_level ? val : null;
    });

    const hidden_root = {
      pid: 'hidden_root',
      id: 0,
      hidden: true
    };

    hidden_root.children = top_level;

    const partners = buildTree(options, dataset, hidden_root, hidden_root)[0];

    const root = d3.hierarchy(hidden_root);

    roots[options.targetDiv] = root;

    const tree_dimensions = get_tree_dimensions(options, dataset);

    const treemap = d3
      .tree()
      .separation(function (a, b) {
        return a.parent === b.parent || a.data.hidden || b.data.hidden
          ? 1.2
          : 3.2;
      })
      .size([tree_dimensions.width + 300, tree_dimensions.height]);

    const nodes = treemap(
      root.sort(function (a, b) {
        return a.data.id - b.data.id;
      })
    );

    let flattenNodes = nodes.descendants();

    adjust_coords(options, nodes, flattenNodes);

    const ptrLinkNodes = linkNodes(flattenNodes, partners);
    let node = ped
      .selectAll('.node')
      .data(nodes.descendants())
      .enter()
      .append('g')
      .attr('transform', function (d) {
        return 'translate(' + d.x + ',' + d.y + ')';
      });

    const pienode = node
      .filter(function (d) {
        return !d.data.hidden;
      })
      .selectAll('pienode')
      .data(function (d) {
        // set the disease data for the pie plot
        let ncancers = d.data?.diagnoses || [];
        const updatedNcancers = ncancers.filter(
          (elem) =>
            elem.displayOnPedigree === true && options.labelsSet.diseases
        );

        const uniqueNcancers = updatedNcancers.reduce((acc, current) => {
          // Find the index of the existing item in the accumulator
          const existingIndex = acc?.findIndex(
            (item) => item.id === current.id
          );

          if (existingIndex === -1) {
            // If the item doesn't exist, push the current item
            acc.push(current);
          } else if (current.clinicallyConfirmed) {
            // If the item exists and current is clinically confirmed, replace it
            acc[existingIndex] = current;
          }
          return acc;
        }, []);
        let cancers = uniqueNcancers?.map(() => 1);
        let diagnoseData =
          uniqueNcancers?.map((elem) => ({
            color: elem.color,
            clinicallyConfirmed: elem.clinicallyConfirmed
          })) || [];

        if (uniqueNcancers.length === 0) cancers = [1];
        return [
          cancers.map(function (val) {
            return {
              cancer: val,
              ncancers: uniqueNcancers.length,
              diagnoseData: diagnoseData,
              id: d.data.pid,
              gender: d.data.gender,
              proband: d.data.proBandId,
              hidden: d.data.hidden,
              affected: d.data.affected,
              exclude: d.data.exclude,
              isHide: d.data.isHide
            };
          })
        ];
      })
      .enter()
      .append('g');

    const defs = pienode.append('defs');

    // Function to create a pattern
    function createPattern(id, color) {
      const pattern = defs
        .append('pattern')
        .attr('id', id)
        .attr('patternUnits', 'userSpaceOnUse')
        .attr('width', 8)
        .attr('height', 8);

      pattern
        .append('rect')
        .attr('width', 8)
        .attr('height', 8)
        .style('fill', color);

      pattern
        .append('circle')
        .attr('cx', 4)
        .attr('cy', 4)
        .attr('r', 2)
        .style('fill', 'black');
    }

    pienode
      .selectAll('path')
      .data(
        d3.pie().value(function (d) {
          return d.cancer;
        })
      )
      .enter()
      .append('path')
      .attr('clip-path', function (d) {
        return 'url(#' + d.data.id + ')';
      }) // clip the rectangle
      .attr('class', 'pienode')
      .attr('d', d3.arc().innerRadius(0).outerRadius(options.symbol_size))
      .style('fill', function (d, i) {
        if (d.data.ncancers === 0 || d.data.isHide) {
          return colors.shade;
        }

        const { clinicallyConfirmed, color } = d.data.diagnoseData[i];
        const patternId = `dot-pattern-${d.data.id}-${i}`; // Unique ID for each pattern

        // Create the pattern dynamically
        createPattern(patternId, color);

        return clinicallyConfirmed ? `url(#${patternId})` : color;
      });

    node
      .filter(function (d) {
        return !d.data.hidden && d.data.fertility && !d.data.spouse;
      })
      .append('path')
      .attr('x', 15)
      .attr('y', 5)
      .style('stroke-width', function (_d) {
        return '.1em';
      })
      .style('stroke', colors.text)
      .attr('d', (d) => {
        let offset = 5;
        if (d.data.gender === GENDER.male) offset = -2;
        if (d.data.gender === GENDER.female) offset = 0;

        if (d.data.fertility === FERTILITY.fertile)
          return `M -15 40 H 15 M 0 ${25 + offset} V 40 `;
        else if (d.data.fertility === FERTILITY.infertile)
          return `M -15 40 H 15 M 0 ${25 + offset} V 40 M -15 45 H 15`;
      });

    node
      .filter(function (d) {
        return !d.data.hidden;
      })
      .filter(function (d) {
        return !d.data.not_set;
      })
      .append('path')
      .attr('class', 'te')
      .attr('shape-rendering', 'geometricPrecision')
      .attr('transform', function (d) {
        return !has_gender(d.data, d3) &&
          !(d.data.miscarriage || d.data.termination)
          ? 'rotate(45)'
          : '';
      })
      .attr(
        'd',
        d3
          .symbol()
          .size(function (_d) {
            return options.symbol_size * options.symbol_size + 2;
          })
          .type(function (d) {
            if (d.data.miscarriage || d.data.termination)
              return d3.symbolTriangle;
            return genderIconMap(d.data, d3) === 'female'
              ? d3.symbolCircle
              : d3.symbolSquare;
          })
      )
      .style('stroke', function (d) {
        return colors.text;
      })
      .style('stroke-width', function (d) {
        return '.11em';
      })
      .style('stroke-dasharray', function (d) {
        return !d.data.exclude ? null : '3, 3';
      })
      .style('fill', '#00000000')
      .style('visibility', function (d) {
        // Hide Node
        return d.data.isHide ? 'hidden' : 'visible';
      })
      .on('click', function (e) {
        e.stopPropagation();
        let d = d3.select(this.parentNode).datum();
        handleClick(e, d, this); // Here this refer to current selected node
      })
      .on('contextmenu', function (e, d) {
        e.preventDefault();
        e.stopPropagation();

        onNodeClick(e, d);
      });

    node
      .filter(function (d) {
        return !d.data.hidden;
      })
      .append('clipPath')
      .attr('id', function (d) {
        return d.data.pid;
      })
      .append('path')
      .attr('class', 'node')
      .attr('transform', function (d) {
        return !has_gender(d.data, d3) &&
          !(d.data.miscarriage || d.data.termination)
          ? 'rotate(45)'
          : '';
      })
      .attr(
        'd',
        d3
          .symbol()
          .size(function (d) {
            if (d.data.hidden)
              return (options.symbol_size * options.symbol_size) / 5;
            return options.symbol_size * options.symbol_size;
          })
          .type(function (d) {
            if (d.data.miscarriage || d.data.termination)
              return d3.symbolTriangle;
            return genderIconMap(d.data, d3) === 'female'
              ? d3.symbolCircle
              : d3.symbolSquare;
          })
      );

    node
      .filter(function (d) {
        return (d.data.dod || d.data.isAlive === 'false') && !d.data.hidden;
      })
      .append('line')
      .attr('stroke', function (d) {
        return d.data.isHide ? '' : colors.text;
      })
      .style('stroke-width', '3px')
      .attr('x1', function (_d) {
        return 0.6 * options.symbol_size;
      })
      .attr('y1', function (_d) {
        return 0.6 * options.symbol_size;
      })
      .attr('x2', function (_d) {
        return -0.6 * options.symbol_size;
      })
      .attr('y2', function (_d) {
        return -0.6 * options.symbol_size;
      });

    // displaying EDIT of node
    node
      .filter(function (d) {
        return !d.data.hidden && options.labelsSet.upn;
      })
      .append('text')
      .attr('x', (d) => d.data.editText && d.data.editText.length * -5) // 4 20 )
      .attr('y', 5)
      .attr('font-size', '1rem')
      .text((d) => (d.data.isHide ? '' : d.data.editText))
      .style('pointer-events', 'none')
      .style('fill', colors.text);

    node
      .filter(function (d) {
        return !d.data.hidden && getAcronym(d.data);
      })
      .append('text')
      .attr('y', function (d) {
        return getLabelPos(d, options).acronym_y;
      })
      .attr('font-size', options.fontSize ? `${options.fontSize}px` : '1rem')
      .text((d) => getAcronym(d.data))
      .attr('x', function () {
        const textElement = d3.select(this);
        const textWidth = textElement.node().getBBox().width;
        return -textWidth / 2;
      })
      .style('fill', colors.text);

    node
      .filter(function (d) {
        return !d.data.hidden && options.labelsSet.upn;
      })
      .append('text')
      .attr('y', function (d) {
        return getLabelPos(d, options).upn_y;
      })
      .attr('font-size', options.fontSize ? `${options.fontSize}px` : '1rem')
      .text((d) =>
        d.data.isHide ? '' : d.data.proBandId ? 1 : d.data.upn ?? ''
      )
      .style('fill', colors.text)
      .attr('x', function (d) {
        const textElement = d3.select(this);
        const textWidth = textElement.node().getBBox().width;
        return -textWidth / 2;
      });

    node
      .filter(function (d) {
        return (
          !d.data.hidden &&
          (options.labelsSet.firstName || options.labelsSet.lastName)
        );
      })
      .append('text')
      .attr('x', -20)
      .attr('y', function (d) {
        return getLabelPos(d, options).name_y;
      })
      .attr('font-size', options.fontSize ? `${options.fontSize}px` : '1rem')
      .text((d) => (d.data.isHide ? '' : getName(d?.data)))
      .style('fill', colors.text)
      .each(function (d) {
        const textElement = d3.select(this);
        const textWidth = textElement.node().getBBox().width;
        textElement.attr('x', -textWidth / 2);
      });

    node
      .filter(function (d) {
        return !d.data.hidden && options.labelsSet.age;
      })
      .append('text')

      .attr('y', function (d) {
        return getLabelPos(d, options).age_y;
      })
      .attr('font-size', options.fontSize ? `${options.fontSize}px` : '1rem')
      .style('fill', colors.text)
      .text((d) => {
        if (d.data.dob) {
          if (d.data.isHide) return;

          let birthday = d.data.dob;
          if (typeof d.data.dob === 'string') birthday = new Date(d.data.dob);

          let today = Date.now();
          if (d.data.dod) today = new Date(d.data.dod);

          const month_diff = today - birthday.getTime();
          const age_dt = new Date(month_diff);
          return 'Age ' + Math.abs(age_dt.getUTCFullYear() - 1970);
        }
      })
      .attr('x', function (d) {
        const textElement = d3.select(this);
        const textWidth = textElement.node().getBBox().width;
        return -textWidth / 2;
      });

    node
      .filter(function (d) {
        return !d.data.hidden && options.labelsSet.personNote;
      })
      .append('text')
      .attr('y', function (d) {
        return getLabelPos(d, options).com_y;
      })
      .attr('font-size', options.fontSize ? `${options.fontSize}px` : '1rem')
      .text((d) => (d.data.isHide ? '' : d.data.personNote))
      .call(wrap, 200)
      .style('fill', colors.text)
      .attr('x', function (d) {
        const textElement = d3.select(this);
        const textWidth = textElement.node().getBBox().width;
        return -textWidth / 2;
      });

    // Phenotypes
    node
      .filter(function (d) {
        return (
          !d.data.hidden &&
          d.data.phenotypes &&
          d.data.phenotypes.length > 0 &&
          (options.labelsSet.phenotypesName || options.labelsSet.phenotypesID)
        );
      })
      .each(function (d) {
        const phenotypes = d.data.phenotypes;
        const group = d3.select(this);

        // Iterate over phenotypes and append each on a new line
        phenotypes.forEach((p, i) => {
          const namePart = options.labelsSet.phenotypesName ? p.name : '';
          const idPart = options.labelsSet.phenotypesID ? `(${p.id})` : '';
          const text = `${namePart}${namePart && idPart ? ' ' : ''}${idPart}`;

          group
            .append('text')
            .attr('y', function () {
              const baseY = getLabelPos(d, options).phenotypes_y;
              return baseY + i * options.fontSize * 1.35;
            })
            .attr(
              'font-size',
              options.fontSize ? `${options.fontSize}px` : '1rem'
            )
            .text(text)
            .style('fill', colors.text)
            .attr('x', function () {
              const textElement = d3.select(this);
              const textWidth = textElement.node().getBBox().width;
              return -textWidth / 2;
            });
        });
      });

    node
      .filter(function (d) {
        return !d.data.hidden && d.data.adopted;
      })
      .append('path')
      .attr('d', function (_d) {
        let dx = -(options.symbol_size * 0.66);
        let dy = -(options.symbol_size * 0.64);
        let indent = options.symbol_size / 3;
        return (
          get_bracket(dx, dy, indent, options) +
          get_bracket(-dx, dy, -indent, options)
        );
      })
      .style('stroke', function (d) {
        return colors.text;
      })
      .style('stroke-width', function (_d) {
        return '.1em';
      })
      .style('stroke-dasharray', function (d) {
        return !d.data.exclude ? null : '3, 3';
      })
      .style('fill', 'none');

    let clash_depth = {};

    // get path looping over node(s)
    let draw_path = function (clash, dx, dy1, dy2, parent_node, cshift) {
      let extend = function (i, l) {
        if (i + 1 < l)
          // && Math.abs(clash[i] - clash[i+1]) < (opts.symbol_size*1.25)
          return extend(++i);
        return i;
      };
      let path = '';
      for (let j = 0; j < clash.length; j++) {
        let k = extend(j, clash.length);
        let dx1 = clash[j].x - dx - cshift;
        let dx2 = clash[k].x + dx + cshift;
        if (parent_node.x > dx1 && parent_node.x < dx2) parent_node.y = dy2;

        path +=
          'L' +
          dx1 +
          ',' +
          (dy1 - cshift) +
          'L' +
          dx1 +
          ',' +
          (dy2 - cshift) +
          'L' +
          dx2 +
          ',' +
          (dy2 - cshift) +
          'L' +
          dx2 +
          ',' +
          (dy1 - cshift);
        j = k;
      }

      return path;
    };

    ped
      .selectAll('.partner')
      .data(ptrLinkNodes)
      .enter()
      .insert('path', 'g')
      .attr('fill', 'none')
      .attr('stroke', colors.text)
      .style('stroke-width', function (_d) {
        return '.1em';
      })
      .attr('shape-rendering', 'auto')
      .attr('d', (d) => {
        let path = checkFertile(d, dataset);

        return path;
      });

    // Partner Horizontal link
    ped
      .selectAll('.partner')
      .data(ptrLinkNodes)
      .enter()
      .insert('path', 'g')
      .attr('fill', 'none')
      .attr('stroke', colors.text)
      .attr('shape-rendering', 'auto')
      .attr('stroke-dasharray', function (d) {
        // for drawing dotted line for casual and casual consanguineous relationship

        let clash = check_ptr_link_clashes(options, d);
        // returning for clash or hidden parents
        if (clash || d.father.data.isHide || d.mother.data.isHide) return;
        if (
          d.parentRelationship === spouseRelations.CASUAL ||
          (d.parentRelationship === spouseRelations.CASUAL &&
            d.isParentConsanguineous)
        )
          return 5;
        return;
      })
      .attr('d', function (d) {
        let node1 = getNodeByName(flattenNodes, d.mother.data.pid);
        let node2 = getNodeByName(flattenNodes, d.father.data.pid);
        let _consanguity = consanguity(node1, node2, options);

        let divorced =
          d.mother.data.divorced &&
          d.mother.data.divorced === d.father.data.pid;

        let x1 = d.mother.x < d.father.x ? d.mother.x : d.father.x;
        let x2 = d.mother.x < d.father.x ? d.father.x : d.mother.x;
        let dy1 = d.mother.y;
        let dy2, dx, parent_node;

        // identify clashes with other nodes at the same depth
        let clash = check_ptr_link_clashes(options, d);

        let path = '';
        if (clash) {
          if (d.mother.depth in clash_depth) clash_depth[d.mother.depth] += 4;
          else clash_depth[d.mother.depth] = 4;

          dy1 -= clash_depth[d.mother.depth];
          dx = clash_depth[d.mother.depth] + options.symbol_size / 2 + 2;

          let parent_nodes = d.mother.data.parent_node;
          let parent_node_pid = parent_nodes[0];
          for (let ii = 0; ii < parent_nodes.length; ii++) {
            if (
              parent_nodes[ii].father.pid === d.father.data.pid &&
              parent_nodes[ii].mother.pid === d.mother.data.pid
            )
              parent_node_pid = parent_nodes[ii].pid;
          }
          parent_node = getNodeByName(flattenNodes, parent_node_pid);
          parent_node.y = dy1; // adjust hgt of parent node
          clash.sort(function (a, b) {
            return a - b;
          });

          dy2 = dy1 - options.symbol_size / 2 - 3;

          path = draw_path(clash, dx, dy1, dy2, parent_node, 0);
        }

        let divorce_path = '';
        if (divorced && !clash)
          divorce_path =
            'M' +
            (x1 + (x2 - x1) * 0.66 + 6) +
            ',' +
            (dy1 - 6) +
            'L' +
            (x1 + (x2 - x1) * 0.66 - 6) +
            ',' +
            (dy1 + 6) +
            'M' +
            (x1 + (x2 - x1) * 0.66 + 10) +
            ',' +
            (dy1 - 6) +
            'L' +
            (x1 + (x2 - x1) * 0.66 - 2) +
            ',' +
            (dy1 + 6);
        if (_consanguity) {
          // consanguinous, draw double line between partners
          dy1 = d.mother.x < d.father.x ? d.mother.y : d.father.y;
          dy2 = d.mother.x < d.father.x ? d.father.y : d.mother.y;

          let cshift = 3;
          if (Math.abs(dy1 - dy2) > 0.1) {
            // DIFFERENT LEVEL
            return (
              'M' +
              x1 +
              ',' +
              dy1 +
              'L' +
              x2 +
              ',' +
              dy2 +
              'M' +
              x1 +
              ',' +
              (dy1 - cshift) +
              'L' +
              x2 +
              ',' +
              (dy2 - cshift)
            );
          } else {
            // SAME LEVEL
            let path2 = clash
              ? draw_path(clash, dx, dy1, dy2, parent_node, cshift)
              : '';
            return (
              'M' +
              x1 +
              ',' +
              dy1 +
              path +
              'L' +
              x2 +
              ',' +
              dy1 +
              'M' +
              x1 +
              ',' +
              (dy1 - cshift) +
              path2 +
              'L' +
              x2 +
              ',' +
              (dy1 - cshift) +
              divorce_path
            );
          }
        }

        // render path on clash
        if (clash) return 'M' + x1 + ',' + dy1 + path + 'L' + x2 + ',' + dy1;

        return getRenderPath(d);
      });

    // for drawing second line for casual consanguineous realtion
    ped
      .selectAll('.partner')
      .data(ptrLinkNodes)
      .enter()
      .insert('path', 'g')
      .attr('fill', 'none')
      .attr('stroke', colors.text)
      .attr('shape-rendering', 'auto')
      .attr('d', (d) => {
        if (
          !(
            d.parentRelationship === spouseRelations.CASUAL &&
            d.isParentConsanguineous
          ) ||
          d.father.data.isHide ||
          d.mother.data.isHide
        )
          return;

        const clash = check_ptr_link_clashes(options, d);
        if (clash) return;

        const { x1, x2, dy1 } = getPathCoordinates(d);
        return 'M' + x1 + ',' + (dy1 + 4) + 'L' + x2 + ',' + (dy1 + 4);
      });

    ped
      .selectAll('.link')
      .data(root.links(nodes.descendants()))
      .enter()
      .filter(function (d) {
        // filter unless debug is set
        return (
          d.target.data.noparents === undefined &&
          d.source.parent !== null &&
          !d.target.data.hidden
        );
      })
      .insert('path', 'g')
      .attr('fill', 'none')
      .attr('stroke-width', function (d) {
        if (
          d.target.data.noparents !== undefined ||
          d.source.parent === null ||
          d.target.data.hidden
        )
          return 1;
        if (d.target.data.not_set) return 0;
        return 1.5;
      })
      .attr('stroke', function (d) {
        // Hide the vertical path
        if (d.target.data?.isHide) return '';

        if (
          d.target.data.noparents !== undefined ||
          d.source.parent === null ||
          d.target.data.hidden
        )
          return 'pink';
        return colors.text;
      })
      .attr('stroke-dasharray', function (d) {
        if (d.target.data.adopted === 'adopted_in') {
          let dash_len = Math.abs(d.source.y - (d.source.y + d.target.y) / 2);
          let dash_array = [dash_len, 0, Math.abs(d.source.x - d.target.x), 0];
          let twins = getTwins(dataset, d.target.data);
          if (twins.length >= 1) dash_len = dash_len * 3;
          for (let usedlen = 0; usedlen < dash_len; usedlen += 10)
            dash_array = dash_array.concat([5, 5]);
          return dash_array;
        } else return null;
      })
      .attr('shape-rendering', function (d) {
        if (d.target.data.mztwin || d.target.data.dztwin)
          return 'geometricPrecision';
        return 'auto';
      })
      .attr('d', function (d) {
        if (
          d.target.data.mztwin ||
          d.target.data.dztwin ||
          d.target.data.cztwin
        ) {
          // get twins
          let twins = getConnectedTwins(dataset, d.target.data);

          if (twins.length >= 1) {
            let xmin = d.target.x;
            let xmax = d.target.x;

            for (let t = 0; t < twins.length; t++) {
              let thisx = getNodeByName(flattenNodes, twins[t].pid).x;
              if (xmin > thisx) xmin = thisx;
              if (xmax < thisx) xmax = thisx;
            }

            let xhbar = '';
            let xques = '';
            let xmid = (xmax + xmin) / 2;
            let ymid = (d.source.y + d.target.y) / 2;
            let yy = (ymid + (d.target.y - options.symbol_size / 2)) / 2;
            let includeCurrentPerson = false;
            let currentTwinIdentifier;
            if (d.target.data.cztwin) {
              includeCurrentPerson = true;
              currentTwinIdentifier = TWIN_IDENTIFIERS.cztwin;
              const cztwins = getTwins(
                dataset,
                d.target.data,
                includeCurrentPerson,
                currentTwinIdentifier
              );
              const index = cztwins.findIndex(
                (p) => p.pid === d.target.data.pid
              );
              let node1, node2;

              if (index < cztwins.length - 1) {
                node1 = getNodeByName(flattenNodes, d.target.data.pid).x;
                node2 = getNodeByName(flattenNodes, cztwins[index + 1].pid).x;
                let x1 = (xmid + node1) / 2;
                let x2 = (xmid + node2) / 2;
                let xf = (x1 + x2) / 2;
                xques = getQuestionPath(xf - 3, yy - 12);
              }
            }

            if (d.target.data.mztwin) {
              // horizontal bar for mztwins
              let nodeMin = d.target.x;
              let nodeMax = d.target.x;
              currentTwinIdentifier = TWIN_IDENTIFIERS.mztwin;
              // return Indentical twins of target node
              const mztwins = getTwins(
                dataset,
                d.target.data,
                includeCurrentPerson,
                currentTwinIdentifier
              );
              for (let t = 0; t < mztwins.length; t++) {
                let nodex = getNodeByName(flattenNodes, mztwins[t].pid).x;
                if (nodeMin > nodex) nodeMin = nodex;
                if (nodeMax < nodex) nodeMax = nodex;
              }
              let initial = (xmid + nodeMin) / 2;
              let final = (xmid + nodeMax) / 2;
              xhbar = 'M' + initial + ',' + yy + 'L' + final + ' ' + yy;
            }

            return (
              'M' +
              d.source.x +
              ',' +
              d.source.y +
              'V' +
              ymid +
              'H' +
              xmid +
              'L' +
              d.target.x +
              ' ' +
              (d.target.y - options.symbol_size / 2) +
              xhbar +
              xques
            );
          }
        }

        if (d.source.data.mother) {
          // check parents depth to see if they are at the same level in the tree
          let ma = getNodeByName(flattenNodes, d.source.data.mother.pid);
          let pa = getNodeByName(flattenNodes, d.source.data.father.pid);

          if (ma.depth !== pa.depth) {
            return (
              'M' +
              d.source.x +
              ',' +
              (ma.y + pa.y) / 2 +
              'H' +
              d.target.x +
              'V' +
              d.target.y
            );
          }
        }

        return (
          'M' +
          d.source.x +
          ',' +
          d.source.y +
          'V' +
          (d.source.y + d.target.y) / 2 +
          'H' +
          d.target.x +
          'V' +
          d.target.y
        );
      });

    const proband = dataset.filter((p) => !!p.proBandId === true)[0];
    let probandNode = getNodeByName(flattenNodes, proband.pid);

    if (probandNode && !probandNode?.data?.isHide) {
      const triid = 'triangle'; /* + makeid(3)*/
      ped
        .append('svg:defs')
        .append('svg:marker') // arrow head
        .attr('id', triid)
        .attr('refX', 20)
        .attr('refY', 6)
        .attr('markerWidth', 20)
        .attr('markerHeight', 20)
        .attr('orient', 'auto')
        .append('path')
        .attr('d', 'M 0 0 12 6 0 12 3 6')
        .style('fill', colors.text);

      ped
        .append('line')
        .attr('x1', probandNode.x - options.symbol_size / 1.2)
        .attr('y1', probandNode.y + options.symbol_size / 1.3)
        .attr('x2', probandNode.x - options.symbol_size / 1.9)
        .attr('y2', probandNode.y + options.symbol_size / 2)
        .attr('stroke-width', 1.3)
        .attr('stroke', '')
        .attr('marker-end', 'url(#' + triid + ')');
    }

    init_zoom(options, svg, pedRef);
  }, [dataset, options]);

  return (
    <div style={{ position: 'relative' }}>
      <div className={classes.btnContainer}>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          style={{ borderRadius: 100 }}
          onClick={() => btn_zoom(svg, 1.05)}
        >
          <ZoomIn />
        </Button>
        <Button
          color="secondary"
          variant="contained"
          size="small"
          style={{ borderRadius: 100 }}
          onClick={() => btn_zoom(svg, 0.95)}
        >
          <ZoomOut />
        </Button>
        {/* <Button
          size="small"
          color="secondary"
          variant="contained"
          style={{ borderRadius: 100 }}
          onClick={undo}
          disabled={getPos() === 0}
        >
          <Undo />
        </Button>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          style={{ borderRadius: 100 }}
          onClick={redo}
          disabled={getPos() === getCount()}
        >
          <Redo />
        </Button> */}
      </div>
      <div ref={pedRef}></div>
    </div>
  );
}

export default Render;
