import React from 'react';
import Table from '../UI/table/Table';
import { AppContext } from 'src/store/ContextStore';
import { parseDate } from 'src/util/util';
import UsersTableStatusCol from '../proband-info/UsersTableStatusCol';
import { GLOBAL_CONSTANTS, TABLE_CONSTANTS } from '../Constants';
import { basePath as customFormBasePath } from 'src/components/custom-form/Constants';
import CustomIconBtn from '../UI/button/CustomIconBtn';

function PatientLinks() {
  const {
    content: {
      formBuilder: { linksGenerated }
    }
  } = React.useContext(AppContext);

  const createUniqueLink = (token) => {
    return `${window.location.origin}${customFormBasePath}?${GLOBAL_CONSTANTS.tokenKey}=${token}`;
  };

  const visitLink = (token) => {
    window.open(createUniqueLink(token), '_blank');
  };

  const table = React.useMemo(() => {
    const columns = [
      {
        title: 'Response ID',
        field: 'responseId',
        width: 160
      },
      { title: 'E-mail', field: 'user.email' },
      { title: 'Form Template', field: 'form.title' },
      { title: 'Workflow', field: 'workflow.workflowName' },

      {
        title: 'Date Generated',
        render: (rowData) => parseDate(rowData.dateGenerated),
        width: 180
      },
      {
        title: 'Link Status',
        field: 'status',
        width: 180,
        sorting: false,
        render: ({ status }) => <UsersTableStatusCol status={status} />
      },
      {
        title: 'Actions',
        width: 180,
        sorting: false,
        render: (rowData) => (
          <CustomIconBtn
            type={'view'}
            tooltip={'view'}
            onClick={() => visitLink(rowData.token)}
          />
        )
      }
    ];

    return (
      <Table
        title="Submissions"
        rows={linksGenerated}
        columns={columns}
        pageSize={5}
        pageSizeOptions={[5, 10, 15, 20, 30, 40, 50]}
        dense={true}
        hoverColor="#f3f3f3"
        tableKey={TABLE_CONSTANTS.patientLinks}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linksGenerated]);

  return <div>{table}</div>;
}

export default PatientLinks;
