import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PedigreePanel from 'src/components/pedigree/PedigreePanel';
import { APICONSTANTS, CONSTANTS, NAV_ITEMS } from 'src/components/Constants';
import BackdropLoader from 'src/components/UI/loader/BackdropLoader';
import {
  UserContext,
  LoadingContext,
  AppContext
} from 'src/store/ContextStore';

import PedigreeChartView from '../pedigree/PedigreeChartView';
import PatientDashboard from './PatientDashboard';
import PatientSettings from './PatientSettings';
import { EVENTS, appReducer } from '../admin/appReducer';
import PatientProband from './PatientProband';
import PatientChat from './patient-chat/PatientChat';
import axios from 'src/axios';

// lazy loading for form builder feature

const ViewFormResponse = React.lazy(() =>
  import('src/components/form-builder/ViewFormResponse')
);

export default function PatientMain({ setNavItems }) {
  const { user } = React.useContext(UserContext);

  // store the ques context
  const [content, dispatchUpdate] = React.useReducer(
    appReducer,
    CONSTANTS.patientInitialContent
  );
  const providerValue = React.useMemo(
    () => ({ content, dispatchUpdate }),
    [content, dispatchUpdate]
  );

  const [loading, setLoading] = React.useState(false);
  const loadingProviderValue = React.useMemo(() => ({ setLoading }), []);

  const {
    settings: { chatFeature },
    selectedGroup
  } = content;

  React.useEffect(() => {
    if (user?.userGroup && user.userGroup.length === 1) {
      dispatchUpdate({
        type: EVENTS.SET_SELECTED_GROUP,
        value: user.userGroup[0]._id
      });
    }
  }, [user]);

  React.useMemo(() => {
    if (selectedGroup && selectedGroup !== 'default') {
      axios
        .get(APICONSTANTS.getChatFeature.replace(':userGroup', selectedGroup))
        .then((res) => {
          dispatchUpdate({
            type: EVENTS.SET_SETTINGS,
            tab: res.data.featureName,
            value: res.data.status
          });
          if (res.data.status) {
            setNavItems(NAV_ITEMS.patient);
          } else {
            setNavItems((navItem) =>
              navItem.filter((item) => item.label !== 'Chat')
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setNavItems((navItem) => navItem.filter((item) => item.label !== 'Chat'));
    }
  }, [selectedGroup]);

  const container = React.useMemo(
    () => (
      <Switch>
        {user.block && <Redirect to={CONSTANTS.manageUserPath} />}

        <Route exact path={CONSTANTS.patientDashboardPath}>
          <PatientDashboard />
        </Route>

        <Route path={CONSTANTS.patientSettingsPath}>
          <PatientSettings />
        </Route>

        <Route path={CONSTANTS.patientProband}>
          <PatientProband />
        </Route>

        {/* Currently not displaying pedigree to patient */}
        {/* <Route path={CONSTANTS.patientPedigreeToolPath} exact>
          <PedigreePanel patient={true} />
        </Route> */}

        <Route path={CONSTANTS.patientPedigreeChartPath}>
          <PedigreeChartView patient={true} />
        </Route>

        {chatFeature && (
          <Route path={CONSTANTS.patientChatPath}>
            <PatientChat />
          </Route>
        )}

        <Redirect to={CONSTANTS.patientDashboardPath} />
      </Switch>
    ),
    [user, user.block, chatFeature]
  );

  return (
    <>
      <LoadingContext.Provider value={loadingProviderValue}>
        <AppContext.Provider value={providerValue}>
          {container}
        </AppContext.Provider>
      </LoadingContext.Provider>
      <BackdropLoader loading={loading}> </BackdropLoader>
    </>
  );
}
