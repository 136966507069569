import React, { useState, useEffect, useContext, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'src/axios';
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  IconButton
} from '@mui/material';
import {
  APICONSTANTS,
  CONSTANTS,
  PHENOTYPES_CONSTANTS
} from 'src/components/Constants';
import { SnackContext } from 'src/store/ContextStore';
import PhenotypesDetailsCard from './PhenotypesDetailsCard';
import { getPhenotypeSearchURL } from 'src/util/util';
import { AppContext } from 'src/store/ContextStore';
import { useAutoTranslation } from 'src/hooks/useTranslate';
import { EVENTS } from 'src/components/admin/appReducer';
import HistoryIcon from '@mui/icons-material/History';

const PhenotypesMenu = ({ data, setDataset, modificationHistoryHandle }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(PHENOTYPES_CONSTANTS['initialPage']);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [fetchedPhenotypes, setFetchedPhenotypes] = useState([]);

  const { setSnack } = useContext(SnackContext);
  const { content, dispatchUpdate } = useContext(AppContext);
  const { pedigreeSelectedUsers: users } = content;
  const { selectedUser: user } = users;

  const t = useAutoTranslation();

  let user_id = useMemo(() => {
    return user?.user_id || user?._id;
  }, [user]);

  // Id for family member
  let familyMemberId = useMemo(() => {
    return data?.data?.familyMemberId;
  }, [data]);

  // Id for proband
  let proBandId = useMemo(() => {
    return data?.data?.proBandId;
  }, [data]);

  const fetchDataByHPOTerm = async (currentPage) => {
    if (searchTerm.length >= 3) {
      let url = getPhenotypeSearchURL(searchTerm, currentPage);
      axios
        .get(url)
        .then(({ data }) => {
          if (isError) setIsError(false);

          const { terms = [], totalCount } = data || {};

          const newOptions =
            terms.map((d) => ({
              id: d.id,
              name: d.name,
              definition: d.definition,
              comment: d.comment,
              synonyms: d.synonyms
            })) || [];

          if (!currentPage) {
            setOptions([...newOptions]);
          } else {
            setOptions((preOption) => [...preOption, ...newOptions]);
          }

          setTotalCount(totalCount);
          setPage(currentPage);
        })
        .catch((err) => {
          setIsError(true);
          console.log('ERRRR-', err);
        });
    } else {
      setOptions([]);
    }
  };

  const getPhenotypes = () => {
    const id = proBandId ?? familyMemberId;
    if (!id || !user_id) return;

    axios
      .get(
        APICONSTANTS.getPhenotypesById
          .replace(':user_id', user_id)
          .replace(':familyMemberId', id),
        {
          params: { isProband: proBandId ? true : false }
        }
      )
      .then(({ data }) => {
        const { phenotypes = [] } = data || {};
        setFetchedPhenotypes(phenotypes);
      })
      .catch((err) => {
        console.log('ERRRR-', err);
      });
  };

  useEffect(() => {
    fetchDataByHPOTerm(PHENOTYPES_CONSTANTS['initialPage']);
    // eslint-disable-next-line
  }, [searchTerm]);

  useEffect(() => {
    getPhenotypes();
    // eslint-disable-next-line
  }, []);

  const handleSelect = (event, value) => {
    if (value) {
      const uniqueData = value.reduce((acc, curr) => {
        const existingItem = acc.find((item) => item.id === curr.id);
        if (!existingItem) {
          acc.push(curr);
        }
        return acc;
      }, []);
      setSelectedItems([...uniqueData]);
      setSearchTerm('');
    }
  };

  const onSave = () => {
    const id = proBandId ?? familyMemberId;
    if (!id || !user_id)
      return setSnack({
        status: true,
        msg: 'Please save the pedigree first',
        severity: 'error'
      });

    if (selectedItems?.length === 0) {
      return setSnack({
        status: true,
        msg: 'No phenotypes selected. Please add at least one phenotype to proceed.',
        severity: 'error'
      });
    }

    setLoading(true);

    const selectedPhenotypes = selectedItems.map((item) => ({
      id: item.id,
      name: item.name
    }));

    const payload = {
      familyId: user.familyId,
      familyMemberId: id,
      user_id: user_id,
      isProband: proBandId ? true : false,
      phenotypes: selectedPhenotypes
    };

    axios
      .post(APICONSTANTS.phenotypes, payload)
      .then((res) => {
        if (res.data?.success) {
          setDataset((prev) => {
            return prev.map((elem) => {
              if (elem.pid === data.data.pid) {
                return {
                  ...elem,
                  phenotypes: res.data.phenotypes
                };
              }
              return elem;
            });
          });
          setFetchedPhenotypes(res.data.phenotypes);
          // Also update global state of phenotypes
          dispatchUpdate({
            type: EVENTS.SET_PEDIGREE_PHENOTYPES,
            value: selectedPhenotypes
          });
          setSearchTerm('');
          setSelectedItems([]);
          setSnack({
            status: true,
            msg: 'Phenotypes saved successfully',
            severity: 'success'
          });
        }
      })
      .catch((err) =>
        setSnack({
          status: true,
          msg: err.response.data.error ?? 'An error has occurred!',
          severity: 'error'
        })
      )
      .finally(() => setLoading(false));
  };

  const handleScroll = (event) => {
    const target = event.target;
    if (
      target.scrollHeight - target.scrollTop === target.clientHeight &&
      options.length < totalCount
    ) {
      let newCurrentPage = page + 1;
      fetchDataByHPOTerm(newCurrentPage);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        {isError && (
          <Grid item xs={12}>
            <Alert severity="warning">
              {t(
                'The HPO service is unreachable and may be offline. Please also check your Internet connection. You will not be able to search HPO until the connection is restored.'
              )}
            </Alert>
          </Grid>
        )}

        <Grid item xs={12}>
          <Autocomplete
            multiple
            clearOnBlur={false}
            id="tags-outlined"
            options={options}
            value={selectedItems}
            getOptionLabel={(option) => option.name}
            onChange={handleSelect}
            ListboxProps={{ onScroll: handleScroll }}
            filterOptions={(options, state) => options}
            // filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search for Phenotypes"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            endIcon={loading ? <CircularProgress size={15} /> : <></>}
            variant="contained"
            color="secondary"
            onClick={onSave}
            disabled={loading}
          >
            Save
          </Button>

          <IconButton
            sx={{ marginLeft: '10px' }}
            onClick={() =>
              modificationHistoryHandle(CONSTANTS.auditLogType['phenotypes'])
            }
          >
            <HistoryIcon />
          </IconButton>
        </Grid>

        {/* Selected Phenotypes Details */}
        {fetchedPhenotypes.map((item) => (
          <Grid key={item.id} item xs={12}>
            <PhenotypesDetailsCard {...item} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default PhenotypesMenu;
