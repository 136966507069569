import React from 'react';
import { List, ListItemButton } from '@mui/material';
import { ThemeContext } from 'src/store/ContextStore';
import { CONSTANTS } from '../../Constants';

const AdoptedMenu = ({
  node,
  deepCopy,
  setDataset,
  setDeepCopy,
  handleClose,
  handleDetailsChange
}) => {
  const { theme } = React.useContext(ThemeContext);
  const { data } = node;

  const adopted = data?.adopted_in || data?.adopted_out;

  const onChange = (value) => {
    const { data } = node;

    const idx = deepCopy.findIndex((person) => data.pid === person.pid);

    deepCopy[idx] = {
      ...deepCopy[idx],
      adopted: value
    };

    handleDetailsChange([deepCopy[idx]]);

    setDeepCopy((deepCopy) => {
      return JSON.parse(JSON.stringify(deepCopy));
    });
    setDataset(deepCopy);
    handleClose();
  };

  return (
    <List>
      {CONSTANTS.adoptedMap.map((option, idx) => {
        return (
          <ListItemButton
            key={idx}
            style={{
              backgroundColor: option.value === adopted && theme.buttonStyle
            }}
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </ListItemButton>
        );
      })}
    </List>
  );
};

export default AdoptedMenu;
