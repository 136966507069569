import { List, ListItemButton } from '@mui/material';
import React from 'react';
import CustomDialog from 'src/components/UI/dialog/CustomDialog';
import SelectSpouseModal from '../pedigree-sidebar/SelectSpouseModal';
import { getSpousesWithChildren } from '../PedigreeChart/utils';
import { CONSTANTS } from 'src/components/Constants';
import { ThemeContext } from 'src/store/ContextStore';

const FertilityMenu = ({
  node,
  deepCopy,
  setDataset,
  setDeepCopy,
  handleClose,
  setPedigreeState,
  handleDetailsChange
}) => {
  const { theme } = React.useContext(ThemeContext);
  const { data } = node;

  const totalSpouses = React.useMemo(() => {
    if (data && data?.spouse) {
      return getSpousesWithChildren(data, deepCopy);
    }
  }, [data, deepCopy]);

  const [openModal, setOpenModal] = React.useState(false);

  const setFertility = (value, selectedSpouse, data) => {
    let relation_fertility = data.relation_fertility ?? [];

    if (value) {
      const rel = relation_fertility.find((rl) => rl.spouse === selectedSpouse);

      if (rel) {
        rel.fertility = value;
      } else {
        relation_fertility.push({ spouse: selectedSpouse, fertility: value });
      }
    } else
      relation_fertility = relation_fertility.filter(
        (rl) => rl.spouse !== selectedSpouse
      );

    return {
      ...data,
      fertility: value,
      relation_fertility
    };
  };

  const updateData = (data, value, selectedSpouse) => {
    let updatedNodes = [];

    const idx = deepCopy.findIndex((person) => data.pid === person.pid);

    deepCopy[idx] = setFertility(value, selectedSpouse, deepCopy[idx]);

    updatedNodes.push(deepCopy[idx]);

    if (selectedSpouse) {
      const spouse_id = deepCopy.findIndex(
        (person) => person.pid === selectedSpouse
      );
      deepCopy[spouse_id] = setFertility(value, data.pid, deepCopy[spouse_id]);
      updatedNodes.push(deepCopy[spouse_id]);
    }

    handleDetailsChange(updatedNodes);

    setPedigreeState(CONSTANTS.pedigreeState.modified);
    setDeepCopy((deepCopy) => {
      return JSON.parse(JSON.stringify(deepCopy));
    });
    setDataset(deepCopy);
    handleClose();
  };

  const node_ref = React.useRef();

  const onChange = (value) => {
    const { data } = node;
    node_ref.current = { data, value };

    if (totalSpouses) {
      if (totalSpouses.length > 1) {
        setOpenModal(true);
      } else {
        updateData(data, value, totalSpouses[0].spouse.pid);
      }
    } else {
      updateData(data, value);
    }
  };

  const handleSelectSpouse = (spouse) => {
    updateData(node_ref.current.data, node_ref.current.value, spouse);
    setOpenModal(false);
  };

  return (
    <List>
      <CustomDialog
        title={'Select Spouse'}
        open={openModal}
        handleClose={() => setOpenModal(false)}
        minHeight="auto"
      >
        <SelectSpouseModal
          spousesWithChildren={totalSpouses}
          handleSelect={handleSelectSpouse}
        />
      </CustomDialog>
      {CONSTANTS.fertilityMap.map((option, idx) => {
        return (
          <ListItemButton
            key={idx}
            style={{
              backgroundColor:
                option.value === data?.fertility && theme.buttonStyle
            }}
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </ListItemButton>
        );
      })}
    </List>
  );
};

export default FertilityMenu;
