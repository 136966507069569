import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback
} from 'react';
import clsx from 'clsx';
import * as d3 from 'd3';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LegendMenu from './LegendMenu';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import { Box, Grid } from '@mui/material';
import HeaderTitle from 'src/components/UI/header/HeaderTitle';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CONSTANTS } from 'src/components/Constants';
import { AppContext, UserContext, ThemeContext } from 'src/store/ContextStore';
import {
  getUserSidebarItems,
  SIDEBAR_ITEMS,
  SIDEBAR_VALUE,
  STUDENT_SIDEBAR_ITEMS,
  getStudentUserSidebarItems,
  checkRelationValid
} from './util';
import RiskScoresMenu from './RiskFactorsMenu';
import AddRelativeMenu from './AddRelativeMenu';
import PersonMenu from './PersonMenu';
import { formatDate, validateNumber } from 'src/util/util';
import {
  getConnectedChildren,
  getSpousesWithChildren
} from '../PedigreeChart/utils';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExportMenu from './ExportMenu';
import LabelsMenu from './LabelsMenu';
import FamilyHistoryMenu from './FamilyHistoryMenu';
import ClinicalNotes from './ClinicalNotes';
import { spouseRelations } from 'src/components/questionnaire/Constants';
import Tags from './Tags';
// import SetupPatientAccessMenu from './SetupPatientAccessMenu';
import PhenotypesMenu from './PhenotypesMenu';
import GenotypesMenu from './GenotypesMenu';
import DiagnosesMenu from './DiagnosesMenu';
import Alert from '@mui/material/Alert';
import Suggestions from './Suggestions';
import EpicEmrButton from 'src/components/integrations/epic/EpicEmrButton';
import ModificationHistoryModal from './ModificationHistoryModal';
import CustomDialog from 'src/components/UI/dialog/CustomDialog';

const useStyles = makeStyles({
  list: {
    width: 380,
    transition: 'width 1s ease'
  },
  expand: {
    width: '95vw'
  },
  fullList: {
    width: 'auto'
  },
  btnContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 10
  },
  expandBtn: {
    position: 'fixed',
    top: '50%',
    marginLeft: -30,
    zIndex: 10
  },
  listContainer: {
    overflowX: 'hidden'
  },
  headerSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  headerTitle: {
    flex: 1,
    textAlign: 'center',
    fontWeight: 500
  },
  closeBtn: {
    marginLeft: 'auto'
  },
  drawer: {
    top: '65px'
  },
  infoContainer: {
    marginBottom: '72px'
  }
});

const modalTitleMap = {
  [CONSTANTS.auditLogType.clinicalRecord]: 'Clinical History',
  [CONSTANTS.auditLogType.riskFactors]: 'Risk History',
  [CONSTANTS.auditLogType.phenotypes]: 'Phenotypes History',
  [CONSTANTS.auditLogType.genotypes]: 'Genotypes History',
  [CONSTANTS.auditLogType.diagnoses]: 'Diagnoses History'
};

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiAccordionDetails);

const defaultValue = {
  gender: 'unknown',
  email: '',
  firstName: '',
  lastName: '',
  dob: '',
  dod: '',
  estimatedDob: false,
  estimatedDod: false,
  mobile: '',
  isAlive: 'true',
  diagnoses: [],
  diagnosesAge: {}
};

export default function PedigreeSideBar({
  data,
  handleDetailsChange,
  deepCopy,
  setDataset,
  setDeepCopy,
  dataset,
  setPedigreeState,
  sidebar,
  setSidebar,
  setData,
  labelsSet,
  setLabelsSet,
  setError
}) {
  const { theme } = useContext(ThemeContext);
  const classes = useStyles(theme);
  const [formValues, setFormValues] = useState(defaultValue);
  const { content } = useContext(AppContext);
  const {
    pedigreeSelectedUsers: users,
    settings: { epicEmrApi }
  } = content;
  const { selectedUser } = users;
  const { user: loggedInUser } = useContext(UserContext);

  const [openModal, setOpenModal] = React.useState(false);
  const [modificationType, setModificationType] = useState(false);

  const handleOpenModel = () => setOpenModal(true);
  const handleCloseModel = () => setOpenModal(false);

  // Id for family member
  let familyMemberId = useMemo(() => {
    return data?.data?.proBandId ?? data?.data?.familyMemberId;
  }, [data]);

  let isStudent = useMemo(() => {
    return loggedInUser.userType === CONSTANTS.userTypes.STUDENT;
  }, [loggedInUser]);

  const modificationHistoryHandle = (type) => {
    setModificationType(type);
    handleOpenModel();
  };

  const unHighlightNodeByPid = (pid) => {
    const parentGroup = d3.selectAll('g').filter(function (d) {
      return d?.data?.pid === pid;
    });

    if (!parentGroup.empty()) {
      const targetPath = parentGroup.select('path.te');

      if (!targetPath.empty()) {
        targetPath
          .style('stroke-width', '0.11em')
          .style('stroke', theme.appearance.text);
      } else {
        console.error('No <path class="te"> found for pid:', pid);
      }
    } else {
      console.error('No <g> element found for pid:', pid);
    }
  };

  const handleClose = () => {
    setSidebar({
      open: false,
      expand: false,
      expandAccordian: []
    });

    if (data) {
      unHighlightNodeByPid(data?.data?.pid);
    }
    setData(null);
  };

  const toggleSideBar = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    if (!open) {
      handleClose();
    } else {
      const defaultExpandedAccordion = [SIDEBAR_VALUE.legend];

      // Fetch state of expanded accordion from local storage
      const savedAccordionState = localStorage.getItem('pedigreeSidebarState');
      const expandedAccordion = savedAccordionState
        ? JSON.parse(savedAccordionState)
        : null;

      // Determine the final accordion state
      const expandAccordian =
        expandedAccordion === null
          ? defaultExpandedAccordion
          : expandedAccordion;

      setSidebar((pre) => ({ ...pre, open: open, expandAccordian }));
    }
  };

  const expandToggleSideBar = () => {
    setSidebar((pre) => ({
      ...pre,
      expand: !pre.expand
    }));
  };

  const handleChangeAccordian = (panel) => (event, newExpanded) => {
    if (newExpanded) localStorage.setItem('sidebar-panel', panel);
    else localStorage.setItem('sidebar-panel', null);
    setSidebar((pre) => {
      const expandAccordian = newExpanded
        ? [...pre.expandAccordian, panel]
        : [...pre.expandAccordian].filter((item) => item !== panel);

      // Save state of accordion to local storage of browser
      localStorage.setItem(
        'pedigreeSidebarState',
        JSON.stringify(expandAccordian)
      );

      return {
        ...pre,
        expandAccordian: expandAccordian
      };
    });
  };

  // adding parent relationship and consanguineous keys in children
  const addParentInfoToChildren = (
    children = [],
    relationship,
    consanguinity
  ) => {
    if (children.length) {
      children.forEach((child) => {
        child.parentRelationship = relationship;
        child.isParentConsanguineous = consanguinity;
      });
    }
    return children;
  };

  // calculating total spouse and its childrens
  const totalSpouse = useMemo(() => {
    if (data?.data && data.data?.spouse) {
      return getSpousesWithChildren(data.data, deepCopy);
    }
  }, [data?.data, deepCopy]);

  // return parent realtionship and consanguinity status from child
  const getParentInfoFromChildren = useCallback(
    (selectedSpouseValue) => {
      if (!totalSpouse?.length)
        return {
          parentRelationship: spouseRelations.MARRIED,
          isParentConsanguineous: false
        };

      // Finding all the connected children
      const connectedChildren =
        getConnectedChildren(totalSpouse, selectedSpouseValue) || [];

      const child = connectedChildren[0] || {};

      const parentRelationship =
        child?.parentRelationship || spouseRelations.MARRIED;
      const isParentConsanguineous = child?.isParentConsanguineous || false;

      return { parentRelationship, isParentConsanguineous };
    },
    [totalSpouse]
  );

  const handleChange = (e) => {
    const value =
      typeof e.target.value === 'string'
        ? e.target.value.trim()
        : e.target.value;

    if (e.target.name === 'genderIdentity') {
      const relationValid = checkRelationValid(
        CONSTANTS.genderMappings[value],
        deepCopy,
        formValues
      );

      if (!relationValid.status) {
        setError(relationValid.message);
        return;
      }

      setFormValues((prevState) => ({
        ...prevState,
        [e.target.name]: value,
        gender: CONSTANTS.genderMappings[value]
      }));
    }
    if (e.target.type === 'checkbox') {
      setFormValues((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.checked
      }));
    } else {
      const additionalFormValues = {};
      if (e.target.name === 'selectedSpouse') {
        const { isParentConsanguineous, parentRelationship } =
          getParentInfoFromChildren(e.target.value);

        additionalFormValues.spouseRelationship = parentRelationship;
        additionalFormValues.isConsanguineous = isParentConsanguineous;
      }

      setFormValues((prevState) => ({
        ...prevState,
        [e.target.name]: value,
        ...(e.target.name === 'isAlive' && value === 'true' ? { dod: '' } : {}),
        ...additionalFormValues
      }));
    }
  };

  const handleDiagnosisAgeChange = (e) => {
    if (e.target.value === '' || validateNumber(e.target.value)) {
      setFormValues({
        ...formValues,
        diagnosesAge: {
          ...formValues.diagnosesAge,
          [e.target.name]: e.target.value
        }
      });
    }
  };

  const dateChangeHandler = (type, value) => {
    let formattedDate = formatDate(value);
    let invalidDate = formattedDate.includes('NaN');

    setFormValues((prevState) => ({
      ...prevState,
      [type]: value,
      ...(type === 'dod' && !invalidDate ? { isAlive: 'false' } : {})
    }));
  };

  useEffect(() => {
    if (data) {
      const formValuesData = {
        pid: data.data.pid,
        spouse: data.data.spouse ?? null,
        gender: data.data.gender ? data.data.gender : 'unknown',
        genderIdentity: data.data.genderIdentity,
        email: data.data.email,
        firstName: data.data.firstName,
        lastName: data.data.lastName,
        dob: data.data.dob ?? null,
        dod: data.data.dod ?? null,
        estimatedDod: data.data['estimatedDod'],
        estimatedDob: data.data['estimatedDob'],
        mobile: data.data.mobile,
        isAlive: data.data.isAlive ?? (data.data.dod ? 'false' : ''),
        diagnoses: data.data.diagnoses ?? [],
        diagnosesAge: data.data.diagnosesAge ?? {},
        isHide: data.data.isHide ?? false,
        ethnicity: data.data.ethnicity ?? []
      };
      if (!!data.data?.spouse && (!!data.data?.upn || !!data.data?.proBandId)) {
        const { parentRelationship, isParentConsanguineous } =
          getParentInfoFromChildren();
        formValuesData.totalSpouse = totalSpouse;
        formValuesData.spouseRelationship = parentRelationship;
        formValuesData.isConsanguineous = isParentConsanguineous;
      }
      setFormValues(formValuesData);
    }
  }, [data]);

  const handleSubmit = () => {
    const updateChangedNodes = [];
    const updateConnectedChildren = [];

    if (formValues.dob) formValues.dob = formatDate(formValues.dob);
    if (formValues.dod) formValues.dod = formatDate(formValues.dod);

    if ((!!data.data?.upn || !!data.data?.proBandId) && !!formValues?.spouse) {
      const connectedChildren = getConnectedChildren(
        formValues.totalSpouse,
        formValues.selectedSpouse
      );
      // updating children with parent info
      const childrenWithParentInfo = addParentInfoToChildren(
        connectedChildren,
        formValues.spouseRelationship,
        formValues.isConsanguineous
      );
      updateConnectedChildren.push(...childrenWithParentInfo);
      // deleting keys
      delete formValues.spouseRelationship;
      delete formValues.isConsanguineous;
      delete formValues.selectedSpouse;
      delete formValues.totalSpouse;
    }

    // Find the target node to update
    const node = deepCopy.filter((p) => p.pid === data.data.pid)[0];

    const updatedNode = { ...node, ...formValues };

    updateChangedNodes.push(updatedNode);

    if (updateConnectedChildren.length) {
      updateChangedNodes.push(...updateConnectedChildren);
    }

    const newDataset = deepCopy.map((person) => {
      const matchedNode = updateChangedNodes.find(
        (node) => node.pid === person.pid
      );
      if (matchedNode) {
        return { ...person, ...matchedNode };
      }
      return person;
    });

    handleDetailsChange(updateChangedNodes);
    setPedigreeState(CONSTANTS.pedigreeState.modified);
    setDeepCopy(() => {
      return JSON.parse(JSON.stringify(newDataset));
    });
    setDataset(newDataset);
  };

  const getAccordianDetails = (value) => {
    switch (value) {
      case SIDEBAR_VALUE.legend:
        return (
          <LegendMenu
            diagnoses={users.diagnoses}
            id="diagnoses-legend-sidebar"
            labelsSet={labelsSet}
            setLabelsSet={setLabelsSet}
          />
        );
      case SIDEBAR_VALUE.person:
        return (
          <PersonMenu
            formValues={formValues}
            handleChange={handleChange}
            dateChangeHandler={dateChangeHandler}
            handleDiagnosisAgeChange={handleDiagnosisAgeChange}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
          />
        );
      case SIDEBAR_VALUE.tags:
        return <Tags user={selectedUser} />;
      case SIDEBAR_VALUE.familyHistory:
        return <FamilyHistoryMenu user={selectedUser} />;
      case SIDEBAR_VALUE.addRelative:
        return (
          <AddRelativeMenu
            setPedigreeState={setPedigreeState}
            user={selectedUser}
            data={data}
            deepCopy={deepCopy}
            setDataset={setDataset}
            setDeepCopy={setDeepCopy}
            // TODO: require a cleanup
            handleClose={() => {}}
            dataset={dataset}
            setError={setError}
            keepOpen={true}
          />
        );
      case SIDEBAR_VALUE.labels:
        return <LabelsMenu labelsSet={labelsSet} setLabelsSet={setLabelsSet} />;
      case SIDEBAR_VALUE.riskFactors:
        return (
          <RiskScoresMenu
            data={data}
            modificationHistoryHandle={modificationHistoryHandle}
          />
        );
      case SIDEBAR_VALUE.export:
        return <ExportMenu user={selectedUser} />;
      // case SIDEBAR_VALUE.setupPatientAccess:
      //   return <SetupPatientAccessMenu handleClose={handleClose} />;
      case SIDEBAR_VALUE.phenotypes:
        return (
          <PhenotypesMenu
            handleClose={handleClose}
            data={data}
            setDataset={setDataset}
            modificationHistoryHandle={modificationHistoryHandle}
          />
        );
      case SIDEBAR_VALUE.genotypes:
        return (
          <GenotypesMenu
            handleClose={handleClose}
            data={data}
            modificationHistoryHandle={modificationHistoryHandle}
          />
        );
      case SIDEBAR_VALUE.diagnoses:
        return (
          <DiagnosesMenu
            handleClose={handleClose}
            data={data}
            setDataset={setDataset}
            modificationHistoryHandle={modificationHistoryHandle}
            deepCopy={deepCopy}
            setDeepCopy={setDeepCopy}
            setPedigreeState={setPedigreeState}
          />
        );
      case SIDEBAR_VALUE.suggestions:
        return <Suggestions data={data} setDataset={setDataset} />;
      case SIDEBAR_VALUE.emr:
        return <EpicEmrButton data={data?.data} emrSettings={epicEmrApi} />;
      case SIDEBAR_VALUE.clinicalNotes:
        return (
          <ClinicalNotes
            data={data}
            modificationHistoryHandle={modificationHistoryHandle}
          />
        );
      default:
        return <div>New Feature Coming Soon...</div>;
    }
  };

  // const getSideBarItems = () => {
  //   const selectedUser = data?.data;
  //   if (selectedUser) {
  //     let showPatientAccessLabel =
  //       selectedUser.proBandId && !selectedUser.email;

  //     if (isStudent) return getStudentUserSidebarItems(showPatientAccessLabel);
  //     else
  //       return getUserSidebarItems({
  //         showPatientAccessLabel,
  //         showEmrTab: epicEmrApi?.enabled
  //       });
  //   } else {
  //     if (isStudent) return STUDENT_SIDEBAR_ITEMS;
  //     else return SIDEBAR_ITEMS;
  //   }
  // };

  const getSideBarItems = () => {
    const selectedUser = data?.data;
    if (selectedUser) {
      let showPatientAccessLabel =
        selectedUser.proBandId && !selectedUser.email;

      if (isStudent) return STUDENT_SIDEBAR_ITEMS;
      else
        return getUserSidebarItems({
          showEmrTab: epicEmrApi?.enabled
        });
    } else {
      if (isStudent) return STUDENT_SIDEBAR_ITEMS;
      else return SIDEBAR_ITEMS;
    }
  };

  const getExpandedValue = (val) => {
    return sidebar.expandAccordian?.includes(val) ? true : false;
  };

  let headerTitle = useMemo(() => {
    let proBandId = data?.data?.proBandId;
    let familyMemberId = data?.data?.familyMemberId;
    if (proBandId || familyMemberId)
      return `PEDIGREE: ${proBandId || familyMemberId}`;
    return `PEDIGREE: ${selectedUser?.familyId}`;
  }, [selectedUser, data]);

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
        [classes.expand]: sidebar.expand
      })}
      role="presentation"
    >
      {/* Header Section */}
      <div className={classes.headerSection}>
        {/* Expandable Button */}
        <IconButton onClick={expandToggleSideBar} size="large">
          {sidebar.expand ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}
        </IconButton>

        {/* Header Title */}
        <Typography
          variant="h5"
          component="h2"
          style={{ color: theme.header, fontWeight: 500 }}
        >
          {headerTitle}
        </Typography>

        {/* Close Button */}
        <IconButton
          onClick={toggleSideBar(false)}
          size="large"
          className={classes.closeBtn}
        >
          <HighlightOffIcon />
        </IconButton>
      </div>

      {/* Sidebar Items */}
      <List className={classes.listContainer}>
        {getSideBarItems().map((item) => (
          <Accordion
            square
            expanded={getExpandedValue(item.value)}
            onChange={handleChangeAccordian(item.value)}
            key={item.value}
          >
            <AccordionSummary
              aria-controls={item.value}
              id={item.value}
              expandIcon={<ArrowDropDownIcon />}
            >
              <Box display={'flex'} alignItems={'center'} gap={1}>
                <IconButton color="primary">{item.icon}</IconButton>
                <Typography pt={0.1}>{item.label}</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {getAccordianDetails(item.value)}
            </AccordionDetails>
          </Accordion>
        ))}
      </List>

      <Grid item xs={12} className={classes.infoContainer}>
        <Alert severity="info">
          TrakGene uses the <b>Human Phenotype Ontology</b> (Version - 0.5.8).
          Find out more at http://www.human-phenotype-ontology.org
        </Alert>
      </Grid>
    </div>
  );

  return (
    <div>
      <div className={classes.btnContainer}>
        <IconButton onClick={toggleSideBar(true)} size="large">
          <MenuIcon />
        </IconButton>
      </div>

      {openModal && (
        <CustomDialog
          title={modalTitleMap[modificationType]}
          open={openModal}
          handleClose={handleCloseModel}
          minHeight="auto"
          maxWidth="lg"
          fullWidth
        >
          <ModificationHistoryModal
            open={openModal}
            modificationType={modificationType}
            handleClose={handleCloseModel}
            familyMemberId={familyMemberId}
          />
        </CustomDialog>
      )}

      {/* SideBar */}
      <Drawer
        variant="persistent"
        anchor="right"
        open={sidebar.open}
        onClose={toggleSideBar(false)}
        BackdropProps={{ invisible: true }}
        classes={{
          paper: classes.drawer
        }}
      >
        {list('right')}
      </Drawer>
    </div>
  );
}
