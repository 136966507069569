import React from 'react';
import { List, ListItemButton } from '@mui/material';
import { ThemeContext } from 'src/store/ContextStore';
import { CONSTANTS as Q_CONSTANTS } from '../../questionnaire/Constants';
import { CONSTANTS } from '../../Constants';
import { checkRelationValid } from '../pedigree-sidebar/util';

const GenderMenu = ({
  node,
  deepCopy,
  setDataset,
  setDeepCopy,
  handleClose,
  setPedigreeState,
  setError,
  handleDetailsChange
}) => {
  const { theme } = React.useContext(ThemeContext);
  const { data } = node;

  const gender = data.genderIdentity ?? data.gender;

  const onChange = (value) => {
    const { data } = node;

    const idx = deepCopy.findIndex((person) => data.pid === person.pid);

    const relationValid = checkRelationValid(
      CONSTANTS.genderMappings[value],
      deepCopy,
      data
    );

    if (!relationValid.status) {
      setError(relationValid.message);
      return;
    }

    deepCopy[idx] = {
      ...deepCopy[idx],
      genderIdentity: value,
      gender: CONSTANTS.genderMappings[value]
    };

    handleDetailsChange([deepCopy[idx]]);

    setPedigreeState(CONSTANTS.pedigreeState.modified);
    setDeepCopy((deepCopy) => {
      return JSON.parse(JSON.stringify(deepCopy));
    });
    setDataset(deepCopy);
    handleClose();
  };

  return (
    <List>
      {Q_CONSTANTS.genderIdentityOptions.map((option, idx) => {
        return (
          <ListItemButton
            key={idx}
            style={{
              backgroundColor:
                option.value.toLowerCase() === gender.toLowerCase() &&
                theme.buttonStyle
            }}
            onClick={() => onChange(option.value)}
          >
            {' '}
            {option.value}{' '}
          </ListItemButton>
        );
      })}
    </List>
  );
};

export default GenderMenu;
