import React, { useContext, useRef } from 'react';
import * as d3 from 'd3';
import { Box, List, ListItemButton } from '@mui/material';
import { ThemeContext } from 'src/store/ContextStore';
import { generateNewUser, RELATIONS } from '../pedigreeUtil';
import { set_Cache } from '../PedigreeChart/cache';
import { APICONSTANTS } from 'src/components/Constants';
import { GENDER } from 'src/components/questionnaire/Constants';
import axios from 'src/axios';

function SetRelation({
  node,
  handleClose,
  selectionModeRef,
  deepCopy,
  setDeepCopy,
  setDataset,
  user,
  setError,
  handleSelectionExit
}) {
  const { theme } = useContext(ThemeContext);

  // This func is to update the node in db
  // Nodes is an array of objects with data and index
  const updatePedigreeChartNode = (nodes) => {
    axios
      .post(APICONSTANTS.updatePedigreeRelaiton.replace(':user_id', user._id), {
        config: nodes
      })
      .catch((err) => setError('ERROR: Updates not saved - please try again'));
  };

  const nodes = d3.selectAll('.te');
  let canvas = d3.select('.canvas');

  let selectedNode1 = null;
  let selectedNode2 = null;

  const relationType = useRef(null);

  selectedNode1 = nodes.filter(function (d) {
    return d.data.pid === node.data.pid;
  });

  const specifyRelation = (relation) => {
    relationType.current = relation;
    selectionModeRef.current = true;
    handleSelectionExit({ node: node.data, relation });
    canvas.style('fill', '#eeeeee');
    selectedNode1.style('stroke-width', '.25em').style('stroke', theme.accent);

    handleClose();
  };

  const updateData = (dataset) => {
    set_Cache(JSON.stringify(dataset), user?.familyId);
    setDeepCopy(() => {
      return JSON.parse(JSON.stringify(dataset));
    });
    setDataset(dataset);
  };

  const setRelationAsSpouse = (startNode, selectedNode) => {
    let target_spouse = selectedNode.data;
    let current_node = startNode.data;

    if (target_spouse.pid === current_node.pid) return;

    const idx = deepCopy.findIndex((person) => current_node.pid === person.pid);
    const t_idx = deepCopy.findIndex(
      (person) => target_spouse.pid === person.pid
    );

    current_node = deepCopy[idx];
    target_spouse = deepCopy[t_idx];

    if (
      current_node.top_level &&
      !current_node.spouse &&
      target_spouse.mother
    ) {
      delete current_node.top_level;
      current_node.father = target_spouse.father;
      current_node.mother = target_spouse.mother;
      current_node.noparents = 'true';
    }

    if (
      target_spouse.top_level &&
      !target_spouse.spouse &&
      current_node.mother
    ) {
      delete target_spouse.top_level;
      target_spouse.father = current_node.father;
      target_spouse.mother = current_node.mother;
      target_spouse.noparents = 'true';
    }

    current_node.spouse = target_spouse.pid;
    target_spouse.spouse = current_node.pid;

    deepCopy[idx] = current_node;
    deepCopy[t_idx] = target_spouse;

    const child = generateNewUser('male', user._id);
    child.mother = target_spouse.pid;
    child.father = current_node.pid;
    child.not_set = 'true';
    child.hidden = 'true';

    let newDataset = [...deepCopy, child];

    updatePedigreeChartNode([
      { data: deepCopy[idx], index: idx },
      { data: deepCopy[t_idx], index: t_idx },
      { data: child, index: null }
    ]);

    updateData(newDataset);
  };

  const setRelationAsChild = (startNode, selectedNode) => {
    let parent_node = selectedNode.data;
    let child_node = startNode.data;

    if (parent_node.pid === child_node.pid) return;
    if (
      child_node.father &&
      child_node.mother &&
      child_node.noparents !== 'true'
    ) {
      setError('Relation Not Allowed : Node already has parents');
      return;
    }
    if (!parent_node.spouse) {
      setError('Relation Not Allowed : No Spouse detected for selected Node');
      return;
    }

    const m_parent = deepCopy.find(
      (person) => parent_node.spouse === person.pid
    );

    const relationValid =
      (parent_node.gender === GENDER.female &&
        m_parent.gender === GENDER.male) ||
      (m_parent.gender === GENDER.female && parent_node.gender === GENDER.male);

    if (!relationValid) {
      setError('Relation Not Allowed : Parent node cannot have offsprings');
      return;
    }

    const idx = deepCopy.findIndex((person) => child_node.pid === person.pid);

    child_node = deepCopy[idx];
    delete child_node.top_level;
    delete child_node.noparents;

    child_node.mother =
      parent_node.gender === GENDER.female ? parent_node.pid : m_parent.pid;
    child_node.father =
      parent_node.gender === GENDER.male ? parent_node.pid : m_parent.pid;

    deepCopy[idx] = child_node;

    const newDataset = deepCopy.filter(
      (child) =>
        !(
          (child.mother === parent_node.pid ||
            child.mother === parent_node.spouse) &&
          (child.father === parent_node.pid ||
            child.father === parent_node.spouse) &&
          child?.hidden
        )
    );

    updatePedigreeChartNode([{ data: deepCopy[idx], index: idx }]);

    updateData(newDataset);
  };

  const setRelationAsSibling = (startNode, selectedNode) => {
    let sibling_node = selectedNode.data;
    let current_node = startNode.data;

    if (current_node.mother && !current_node.noparents) {
      setError('Relation Not Allowed : The node already has assigned parents');
      return;
    }

    if (sibling_node.pid === current_node.pid) return;
    if (!sibling_node.mother || sibling_node.noparents) {
      setError(
        'Relation Not Allowed : No Parents detected for the selected Node'
      );
      return;
    }

    const idx = deepCopy.findIndex((person) => current_node.pid === person.pid);

    current_node = deepCopy[idx];
    current_node.mother = sibling_node.mother;
    current_node.father = sibling_node.father;

    delete current_node.top_level;
    delete current_node.noparents;

    deepCopy[idx] = current_node;
    updatePedigreeChartNode([{ data: deepCopy[idx], index: idx }]);
    updateData(deepCopy);
  };

  const validateAndSetRelation = (startNode, selectedNode) => {
    switch (relationType.current) {
      case RELATIONS.partner:
        setRelationAsSpouse(startNode, selectedNode);
        break;
      case RELATIONS.children:
        setRelationAsChild(startNode, selectedNode);
        break;
      case RELATIONS.siblings:
        setRelationAsSibling(startNode, selectedNode);
        break;
      default:
        return;
    }
  };

  const handleSelectRelation = (node_1, node_2) => {
    validateAndSetRelation(node_1, node_2);

    nodes.on('click.secondSelection', null);
    canvas.style(
      'fill',
      theme.appearance.value === 'light' ? 'white' : theme.appearance.shade
    );
    selectedNode1.style('stroke-width', '.11em').style('stroke', 'black');
    selectedNode2.style('stroke-width', '.11em').style('stroke', 'black');
    selectionModeRef.current = false;
    handleSelectionExit(false);
  };

  nodes.on('click.secondSelection', function (e) {
    e.stopPropagation();

    let d = d3.select(this);
    selectedNode2 = d;
    selectedNode2.style('stroke-width', '.25em').style('stroke', theme.accent);

    handleSelectRelation(node, d.datum());
  });

  return (
    <Box>
      <List>
        <ListItemButton onClick={() => specifyRelation(RELATIONS.partner)}>
          Spouse
        </ListItemButton>
        <ListItemButton onClick={() => specifyRelation(RELATIONS.siblings)}>
          Sibling
        </ListItemButton>
        <ListItemButton onClick={() => specifyRelation(RELATIONS.children)}>
          Child
        </ListItemButton>
      </List>
    </Box>
  );
}
export default SetRelation;
