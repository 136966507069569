import React from 'react';
import { Box, FormControl, Select, MenuItem } from '@mui/material';

export default function AssociatedNote({
  name,
  value,
  handleChange,
  noteOptions
}) {
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const getIndex = (value) => {
      let i = 0;
      for (const option of noteOptions) {
        if (option._id == value?._id) return i;
        i++;
      }
      return -1;
    };
    setIndex(getIndex(value));
  }, [value, noteOptions]);

  return (
    <Box sx={{ display: 'flex', position: 'relative' }}>
      <FormControl fullWidth>
        <Select
          name={name}
          style={{ width: '100%' }}
          value={index !== -1 ? noteOptions[index] : { label: '', _id: '' }}
          onChange={handleChange}
        >
          {noteOptions.map((option) => (
            <MenuItem value={option} key={option._id}>
              {option.label}
            </MenuItem>
          ))}
          <MenuItem value={{ label: 'none', _id: '' }}>None</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
